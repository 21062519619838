import React, { useState, useRef, useEffect, Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchApplicantsRequest } from "../../../redax/actions/applicantsActions/applicantsActions";
import { fetchLoginRequest } from "../../../redax/actions/loginActions/loginActions";
import { fetchRegionsRequest } from "../../../redax/actions/regionsActions/regionsActions";
import { RootState } from "../../../redax/reducers/rootReducer";
import { fetchSavedConditionsRequest } from "../../../redax/actions/savedConditionsActions/savedConditionsActions.tsx";
import { API_BASE_ADDRESS } from "../../../redax/API";
import { fetchSavedConditionsDeleteRequest } from "../../../redax/actions/savedConditionsDeleteActions/savedConditionsDeleteActions";
import Loader from "../../loader/loader";

function SavedCondions() {
  const [savedData, setSavedData] = useState<any[]>([]);
  const dispatch = useDispatch();
  const loginData = useSelector((state: RootState) => state.login);
  const applicants = useSelector((state: RootState) => state.applicants);
  const savedConditionsDelete = useSelector((state: RootState) => state.savedConditionsDelete);
  const regions = useSelector((state: RootState) => state.regions);
  const savedConditions = useSelector((state: RootState) => state.savedConditions);
  const [loadScreen, setLoadScreen] = useState(true);
  let param = '';
  const pageTitle = '障がい者雇用専門の求人サイトの検索条件｜パラちゃんねる';
  document.title = pageTitle;
  const navigate = useNavigate();

    useEffect(() => {
      if (!localStorage.getItem('jobParachannelAuthToken')) {
        navigate('/login');
      } else {
        // dispatch(fetchLoginRequest('', '',''));
      }
    }, [navigate, dispatch]);


  useEffect(() => {
    if (loginData.pending) {
      // dispatch(fetchApplicantsRequest(loginData.login.resource.id, 'get', 0));
      // dispatch(fetchRegionsRequest());
      param = '/saved_conditions?applicant_id=' + loginData.login.resource.id;
      dispatch(fetchSavedConditionsRequest(param));
    }
}, [loginData.pending, dispatch]);


useEffect(() => {
  if (savedConditions.pending) {
      setLoadScreen(false);
      setSavedData(savedConditions.savedConditions.saved_conditions)
  }
}, [savedConditions.pending]);

useEffect(() => {
  if(savedConditionsDelete.pending) {
    window.location.reload();
  } else if(savedConditionsDelete.error != null) {
    setLoadScreen(false);
  }
}, [savedConditionsDelete.pending, savedConditionsDelete.error]);

const deleteSavedConditions = async (id: any, name : any) => {
  if (window.confirm("以下の保存条件を削除しますか？\n" + "【 " + name +"】")) {
    try {
      setLoadScreen(true);
      dispatch(fetchSavedConditionsDeleteRequest(id));
    } catch (error) {
      console.error(error);
    }
  }
};

    return (
      <main id="search">
      {loadScreen ? <Loader /> : <></>}
      <section className="wrapper">
        <h2 className="ttl type3">検索条件一覧</h2>
        {savedData.length !== 0 ? (
          <ul>
            {savedData.map((data: any, index: any) => (
              <li key={index}>
                <a href={'/recruitments/?' + data.params} className="">{data.name}</a> <a href="#" onClick={() => deleteSavedConditions(data.id, data.name)}>削除する</a>
              </li>
            ))}
          </ul>
        ) : (
          <p className="">保存した条件はありません</p>
        )}
      </section>
    </main>
    );
  }
  
  export default SavedCondions;
