
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redax/reducers/rootReducer";
import { useEffect, useState } from "react";
import { fetchLoginRequest } from "../../../redax/actions/loginActions/loginActions";
import { useLocation, useNavigate } from "react-router-dom";

function Header() {
  const dispatch = useDispatch();
  const loginData = useSelector((state: RootState) => state.login);
  const [isLoginType, setLoginType] = useState(null);
  const [favoriteList, setFavoriteList] = useState('/login/');
  const [isLogin, setLogin] = useState(false);
  const [load, setLoad] = useState<boolean>(true);
  // const navigate = useNavigate();

  useEffect(() => {
    if(localStorage.getItem('jobParachannelAuthToken')) {
      if(load) {
        dispatch(fetchLoginRequest('', '',''));
        setLoad(false);
      }
      if(loginData !== undefined){
        if(loginData.pending){
          setLogin(true);
          setLoginType(loginData.login.authenticate.resource_type);
          setFavoriteList('/mypage/applications/?tab=3');
        }
      }
    }
  }, [dispatch, loginData]);

  function logout() {
    if (localStorage.entryFlag) {
      localStorage.removeItem('entryFlag')
    }
    if (localStorage.jobParachannelAuthToken) {
      localStorage.removeItem('jobParachannelAuthToken')
      // navigate('/');
      window.location.href = '/';
    }
  }


  if(window.location.href.includes('mypage') || window.location.href.includes('recruiter') ) {
    if (window.location.href.includes('preview') || window.location.href.includes('recruiter_lp')
      || window.location.href.includes('login') || window.location.href.includes('signup') || window.location.href.includes('password')) {
      return (
        <header className="wrapper">
        <div className="logo">
          <a href="/">
            <h1><img src="/assets/img/logo.svg" alt="パラちゃんねる"/></h1>
            <p>特性を生かしたお仕事探しなら<br />パラちゃんねる！(^-^)/</p>
          </a>
        </div>
        <ul>
          <li><a href ="/about/"><img src="/assets/img/header_icon04.svg" alt=""/>このサイトについて</a></li>
          <li><a href ="/guide/"><img src="/assets/img/header_icon05.svg" alt=""/>使い方ガイド</a></li>
          {isLoginType === 'Applicant' ? <li><a href={favoriteList}><img src="/assets/img/header_icon01.svg" alt=""/>お気に入り</a></li> : <></>}
          {isLoginType !== 'Companies::Recruiter' ? <li><a href ="/saved_conditions/"><img src="/assets/img/header_icon02.svg" alt=""/>保存した条件</a></li>: <></>}
        </ul>
        {!isLogin ?
        <div className="btn_box">
        <p>登録は<span>1分</span>で完了</p>
          <a href ="/login"><img src="/assets/img/header_icon03.svg" alt=""/>ログイン／登録</a>
          <a href ="/recruiter_lp/">採用担当者様はこちら</a>
        </div>
        : 
        <div className="btn_box">
          {isLoginType === 'Applicant' ? <a href="/login/"><img src="/assets/img/header_icon03.svg" alt=""/>マイページ</a> : <a href="/recruiter/login/"><img src="/assets/img/header_icon03.svg" alt=""/>マイページ</a>}
        
        <a href="#" onClick={logout}>ログアウト</a>
        </div>
        }
      </header>
      );
    } else {
      return <></>;
    }
  } else {
    return (
      <header className="wrapper">
      <div className="logo">
        <a href="/">
          <h1><img src="/assets/img/logo.svg" alt="パラちゃんねる"/></h1>
          <p>特性を生かしたお仕事探しなら<br />パラちゃんねる！(^-^)/</p>
        </a>
      </div>
      <ul>
        <li><a href ="/about/"><img src="/assets/img/header_icon04.svg" alt=""/>このサイトについて</a></li>
        <li><a href ="/guide/"><img src="/assets/img/header_icon05.svg" alt=""/>使い方ガイド</a></li>
        {isLoginType === 'Applicant' ? <li><a href={favoriteList}><img src="/assets/img/header_icon01.svg" alt=""/>お気に入り</a></li> : <></>}
        {isLoginType !== 'Companies::Recruiter' ? <li><a href ="/saved_conditions/"><img src="/assets/img/header_icon02.svg" alt=""/>保存した条件</a></li>: <></>}
      </ul>
      {!isLogin ?
      <div className="btn_box">
      <p>登録は<span>1分</span>で完了</p>
        <a href ="/login"><img src="/assets/img/header_icon03.svg" alt=""/>ログイン／登録</a>
        <a href ="/recruiter_lp/">採用担当者様はこちら</a>
      </div>
      : 
      <div className="btn_box">
        {isLoginType === 'Applicant' ? <a href="/login/"><img src="/assets/img/header_icon03.svg" alt=""/>マイページ</a> : <a href="/recruiter/login/"><img src="/assets/img/header_icon03.svg" alt=""/>マイページ</a>}
      
      <a href="#" onClick={logout}>ログアウト</a>
      </div>
      }
    </header>
    );
    
  }

}

export default Header;