import { useEffect, useRef, useState } from 'react';
import MypageSideBar from '../../../components/MypageSideBar/MypageSideBar';
import './profile.scss';
import { useDispatch, useSelector } from 'react-redux';
import { fetchApplicantsRequest } from '../../../redax/actions/applicantsActions/applicantsActions';
import { RootState } from '../../../redax/reducers/rootReducer';
import ProfileTab from './tab';
import ProfileIndex from '.';
import { fetchLoginRequest } from '../../../redax/actions/loginActions/loginActions';
import { fetchPostCodeRequest } from '../../../redax/actions/postcodeActions/postcodeActions';
import { fetchRegionsRequest } from '../../../redax/actions/regionsActions/regionsActions';
import { fetchPutRegionFailure, fetchPutRegionRequest } from '../../../redax/actions/putRegionActions/putRegionActions';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import Loader from '../../loader/loader';
let bday = '';

export default function ProfilePage() {
    const [loadScreen, setLoadScreen] = useState(true);
    const fileInputRef = useRef<HTMLInputElement>(null);
    let applicant: any = [];
    let educational_background = [];
    let education_background = [];
    const [tempolary, settempolary] = useState(false);
    const [family_name, setFamilyName] = useState<string | null>(null);
    const [first_name, setFirstName] = useState<string | null>(null);
    const [family_name_kana, setFamilyNameKana] = useState<string | null>(null);
    const [first_name_kana, setFirstNameKana] = useState<string | null>(null);
    const [postCode, setPostCode] = useState<string | null>(null);
    const [address, setAddress] = useState('');
    const [mobile_number, setMobileNumber] = useState('');
    const [prefectureId, setPrefectureId] = useState(String);
    const [prefectureName, setPrefectureName] = useState(String);
    const [applicantPrefectureName, setApplicantPrefectureName] = useState(String);
    const [cityId, setCityId] = useState(String);
    const [cityName, setCityName] = useState(String);
    const [applicantCityName, setApplicantCityName] = useState(String);
    const [face_photo, setFacePhoto] = useState<any>(null);
    const [isFinalEducationModal, setIsFinalEducationModal] = useState(false);
    const [isGraduationDateModal, setIsGraduationDateModal] = useState(false);
    const [isBirthdayOpen, setBirthdayOpen] = useState(false);
    const [isNewGraduate, setNewGraduate] = useState(false);
    const [familyName, setFamylyName] = useState(null);
    const [email, setEmail] = useState(null);
    const [resourceId, setResourceId] = useState(null);
    const [authenticate, setAuthenticate] = useState(null);
    const [btn, setBtn] = useState(false);
    const [lostPost, setLoadPost] = useState(false);
    const [invalid, setInvalid] = useState(false);
    const [errorPostCode, setErrorPostCode] = useState(false);  
    const [errorFamilyName, setErrorFamilyName] = useState(false);
    const [errorFamilyNameKana, setErrorFamilyNameKana] = useState(false);
    const [errorFamilyNameMaxlength, setErrorFamilyNameMaxLength] = useState(false);
    const [errorFamilyNameKanaMaxlength, setErrorFamilyNameKanaMaxLength] = useState(false);
    const [errorFamilyNameKanaCheck, setErrorFamilyNameKanaCheck] = useState(false);
    const [errorFirstName, setErrorFirstName] = useState(false);
    const [errorFirstNameKana, setErrorFirstNameKana] = useState(false);
    const [errorFirstNameMaxlength, setErrorFirstNameMaxLength] = useState(false);
    const [errorFirstNameKanaMaxlength, setErrorFirstNameKanaMaxLength] = useState(false);
    const [errorFirstNameKanaCheck, setErrorFirstNameKanaCheck] = useState(false);
    const [errorPostcodeMinMaxLength, setErrorPostcodeMinMaxLength] = useState(false);
    const [errorMobileNumber, setErrorMobileNumber] = useState(false);
    const [postCodeNumeric, setPostCodeNumeric] = useState(false);
    const [postCodeMinLength, setPostCodeMinLength] = useState(false);
    const [postCodeMaxLength, setPostCodeMaxLength] = useState(false);
    const [mobileNumericError, setMobileNumericError] = useState(false);
    const [mobileMinLengthError, setMobileMinLengthError] = useState(false);
    const [mobileMaxLengthError, setMobileMaxLengthError] = useState(false);
    const [birthday, setBirthday] = useState('');
    const [finalEducationData, setFinalEducationData] = useState<any[]>([]); 
    const [selectedFinalEducationId, setSelectedFinalEducationId] = useState(null);
    const [jobCareerCountId, setJobCareerCountId] = useState<any>(null);
    const [selectedGraduationDate, setSelectedGraduationDate] = useState<any>(null);
    const [minYear, setMinYear] = useState<any>(null);
    const [maxYear, setMaxYear] = useState<any>(null);
    const [baseMonth, setBaseMonth] = useState<any>(null);
    const [baseMonthThanAgo, setBaseMonthThanAgo] = useState(false);
    const [onlyPast, setOnlyPast] = useState(false);
    const [enableNone, setEnableNone] = useState(false);
    const [gender, setGender] = useState(null); 
    const [classErr, setClassEr] = useState('');
    const [classErr2, setClassEr2] = useState('');
    const [selectedFinalEducationName, setSelectedFinalEducationName] = useState<any>(null);
    const [navigation_book, setNavigationBook] = useState(null); 
    const [edited, setEdited] = useState(false); 
    const [currentSlide, setCurrentSlide] = useState(0);
    let monthly = 13;    
    let monthEndDay: any;
    let dataSetNames = ['年', '月', '日'];    
    let currentSlideNumber = 0;
    let postCodeMaxLen = 7;
    let mobileMinLen = 10;
    let mobileMaxLen = 11;
    let finalEducationDataSetName = '最終学歴';
    let finalEducationTitle = '最終学歴';
    let graduationdataSetName="卒業年度"
    let graduationDataSetNames = ['年', '月']
    let graduationTitle = '卒業・卒業見込み年度';
    let applicantBirthYear: any;
    let applicantBirthMonth: any;
    let applicantBirthDay: any;
    // let BirthDay: any;
    const [years, setYears] = useState<any[]>([]);
    const [months, setMonths] = useState<any[]>([]); 
    const [days, setDays] = useState<any[]>([]); 
    const [filterYears, setFilterYears] = useState<any[]>([]);
    const [filterMonths, setFilterMonths] = useState<any[]>([]);
    const [year, setYear] = useState();
    const [month, setMonth] = useState();
    const [graduationYear, setGraduationYear] = useState<any>();
    const [graduationMonth, setGraduationMonth] = useState<any>();
    const [day, setDay] = useState();
    const [birthModalSlide, setBirthModalSlide] = useState(0);
    const [graduationModalSlide, setGraduationModalSlide] = useState(0);
    const dispatch = useDispatch();  
    const navigate = useNavigate(); 
    const loginData = useSelector((state: RootState) => state.login);
    const regions = useSelector((state: RootState) => state.regions);
    const putregion = useSelector((state: RootState) => state.putregion);
    const applicants   = useSelector((state: RootState) => state.applicants);    
    const { postcode } = useSelector((state: RootState) => state.postcode);
    const [firstLoad, setFirstLoad] = useState(false);
    const pageTitle = 'WEB履歴書（プロフィール）｜パラちゃんねる';
    document.title = pageTitle;
    const queryParams = new URLSearchParams(window.location.search);
    const refs = useRef<any>(null);

    const findValue = (value: any, masterName: string | number) => {
        const dataSet = regions.regions[masterName];
        if (dataSet === undefined) {
            return null;
        }
        return value && value !== undefined ? dataSet.find((v: { id: number; }) => v.id === Number(value))?.name : null;
    };

    useEffect(() => {
        if (localStorage.getItem('jobParachannelAuthToken')) {
            dispatch(fetchLoginRequest('', '',''))            
            if(queryParams.get('tab') != null || queryParams.get('next') != null) {
                setTimeout(() => {              
                    refs.current?.scrollIntoView({behavior:"smooth", block:"start"})
                }, 0);
            }
        } else (
            navigate('/login')
        )
    }, [dispatch]);

    useEffect(() => {
        if(!edited) return;

        window.addEventListener("beforeunload", confirmSave);

        return () => window.removeEventListener("beforeunload", confirmSave);

    },[edited]);

    function confirmSave (event: any) {
        event.preventDefault();
        if (edited) {
            event.returnValue = "編集中のデータは保存されていませんが、移動しますか？"
        }
    }

    useEffect(() => {
        dispatch(fetchRegionsRequest());
      }, [dispatch]);
  
      useEffect(() => {
          if (loginData.pending) {
            if(!loginData.login.is_init_complete) {
            //   navigate('/mypage/init/');
              window.location.href = '/mypage/init/';
            }
            setResourceId(loginData.login.resource.id);
            setFamylyName(loginData.login.resource.family_name);
            setAuthenticate(loginData.login.resource.id);
            dispatch(fetchApplicantsRequest(loginData.login.resource.id, 'get', 0));
          }
      }, [dispatch, loginData.pending]);
    
    useEffect(() => {
        if (applicants.pending && !lostPost && !firstLoad) {
                setLoadScreen(false) 
                applicant = applicants.applicants.applicant;
                setFacePhoto(applicant.face_photo);
                educational_background = applicant.educational_background;
                let maxYear = dayjs().year() + 6;
                let minYear = dayjs().year() - 50;
                setMaxYear(maxYear);
                setMinYear(minYear);
                if (educational_background.new_graduate == true) {
                    minYear = dayjs().year() - 3;
                    setMinYear(minYear);
                } else {
                    maxYear = dayjs().year();
                    setMaxYear(maxYear);
                }
                if (educational_background.graduation_date) {
                    setGraduationYear(educational_background.graduation_date.split('-')[0]);
                    setGraduationMonth(educational_background.graduation_date.split('-')[1]);
                }
                filteredYears();
                setSelectedFinalEducationId(educational_background.final_education_id);
                setSelectedGraduationDate(educational_background.graduation_date);
                setBirthday(applicant.birthday)
                setEmail(applicant.email);
                setFamilyName(applicant.family_name);
                setFirstName(applicant.first_name);
                setFamilyNameKana(applicant.family_name_kana);
                setFirstNameKana(applicant.first_name_kana);
                setPostCode(applicant.postcode);
                if (applicant.address !== null) {
                    setAddress(applicant.address);
                }
                if(applicant.prefecture_id) {
                    setPrefectureId(applicant.prefecture_id);
                    setPrefectureName(applicant.prefecture_name);
                }
                if(applicant.city_id) {
                    setCityId(applicant.city_id);
                    setCityName(applicant.city_name);
                }  
                if(applicant.mobile_number) {
                    setMobileNumber(applicant.mobile_number);
                }               
                setFirstLoad(true);
                setApplicantPrefectureName(applicant.prefecture_name);
                setApplicantCityName(applicant.city_name);
                setGender(applicant.gender_id);
                education_background = applicant.educational_background;
                if(education_background.new_graduate !== undefined){
                    setNewGraduate(education_background.new_graduate);
                    setJobCareerCountId(1);
                }
                setNavigationBook(applicant.navigation_book)

        } else if(applicants.error != null) {
            setLoadScreen(false);
            navigate('/error?code=500');
        }
    }, [applicants, lostPost, firstLoad]);

    useEffect(() => {
        if (regions.pending) {
          const regionsData = regions.regions;
          setFinalEducationData(regionsData.final_educations);
        }
      }, [regions.pending]);

    const openModalFinalEducation = () => {
        setEdited(true);
        setIsFinalEducationModal(true);
    }

    const openModalGraduationDate = () => {
        setEdited(true);
        setIsGraduationDateModal(true);
        filteredYears();
    }

    function closeModalFinalEducation() {
        setIsFinalEducationModal(false);
    }

    function closeModalGraduationDate() {
        setIsGraduationDateModal(false);
    }

    // const setImage = async (
    //     event: React.ChangeEvent<HTMLInputElement>
    //   ): Promise<any> => {
    //     const file = event.target.files ? event.target.files[0] : null;
    //     if (file != null) {
    //         const fileLoaded = URL.createObjectURL(file);
    //         setFacePhoto(fileLoaded);
    //     }
    // };
    function setImage() {
        setEdited(true);
        const fileInput = fileInputRef.current;
        if (fileInput && fileInput.files && fileInput.files.length > 0) {
          const fileImg = fileInput.files[0];
          if (fileImg.type.startsWith('image/')) {
            const logoUrl = URL.createObjectURL(fileImg);
            const reader = new FileReader();
            reader.readAsDataURL(fileImg);
            reader.onload = function () {
                setFacePhoto(reader.result);
            };
          }
        }
      }

    function calcAge () {
        if (birthday) {
            const today = new Date();
            const b = birthday.split('-')
            const baseAge = today.getFullYear() - parseInt(b[0]);
            return baseAge+'歳';
        } else {
          return ''
        }
    }

    useEffect(() => {
        if(btn) {
            setLoadPost(true);
            dispatch(fetchPostCodeRequest(postCode));
            setBtn(false);
        }
        if(postcode !== undefined) {
            if(postcode.region !== undefined) {
                if(postcode.prefecture !== undefined) {
                    setPrefectureId(postcode.prefecture.id);
                    setPrefectureName(postcode.prefecture.name);
                }
                if(postcode.city !== undefined) {
                    setCityId(postcode.city.id);
                    setCityName(postcode.city.name);
                }                
            }
        }
    }, [postcode, dispatch, btn]);

    const onChangeInputBirthModal = (event: any) => { 
        const {name, value} = event.target;
        if(name === 'year') {
            setYear(value);
            setBirthModalSlide(1);
        }
        if(name === 'month') {
            setMonth(value);
            setBirthModalSlide(2);
        }
        if(name === 'date') {
            setDay(value);
            setBirthday(`${year}-${month}-${value}`)
            setBirthdayOpen(false);
            setBirthModalSlide(0);
            bday = '';
        }
        
    }

    const getYears = () => {
        const goBackYears = 62;
        const currentYear = dayjs().year() - 17;
        let startYear = currentYear - goBackYears;
        const yearsCal = [];
        for (let i = 1; i <= goBackYears; i++) {
            yearsCal.push(startYear);
            startYear++;
        }
        setYears(yearsCal);
        const monthAll = [];
        for (let i = 1; i < monthly; i++) {
            monthAll.push(i);
        }
        setMonths(monthAll);
    };

    if(year !== undefined && month !== undefined) { 
        let chooseDay = new Date(); 
        chooseDay.setFullYear(year, month, 0);
        let d = chooseDay.toISOString().substring(8, 10);
        monthEndDay = d;
    }

    useEffect(() => {
        if(monthEndDay !== null) {
            monthEndDay = parseInt(monthEndDay) + 1;  
            const monthDays = [];  
            for (let i = 1; i < parseInt(monthEndDay); i++) {
                monthDays.push(i);
            }
            setDays(monthDays);
        }
    }, [monthEndDay]);
    const formatYM = (dateString : any) => {
         if (dateString === null) {
            return ''
        } 
        const [year, month] = dateString.split('-').map((component: any) => parseInt(component));
        return `${year}年${month}月`;
    };
    const formatYMD = (dateString : any) => {
        if (dateString === null) {
           return ''
       } 
       const [year, month, day] = dateString.split('-').map((component: any) => parseInt(component));
       return `${year}年${month}月${day}日`;
   };

    function dataValidation () {
        let err = false;        
        if(face_photo.length == 0) {
            err = true;
        }
        if(family_name == null) {
            err = true;
        }
        if(first_name == null) {
            err = true;
        }
        if(family_name_kana == null) {
            err = true;
        } 
        if(first_name_kana == null) {
            err = true;
        }
        if(birthday == null) {
            err = true;
        }
        if(postCode == null) {
            err = true;
        }
        if(prefectureId == null) {
            err = true;
        }
        if(cityId == null) {
            err = true;
        }
        if(postCode!== null && address.length === 0) {
            err = true;
            setClassEr2('.error')
        } else{
            setClassEr2('')
        }
        if(mobile_number == null || mobile_number == "") {
            err = true;
        }
        if(mobile_number.length !== 10) {
            err = true;
            setClassEr('.error')
        } else{
            setClassEr('')
        }
        // if(selectedFinalEducationId == null) {
        //     err = true;
        // }
        // if(selectedGraduationDate == null) {
        //     err = true;
        // }
        return err;
    }

    useEffect(() => {
        if (putregion.pending) {
          if (localStorage.entryFlag) {
            if(window.confirm("エントリーページへ戻りますか？")) {
              let recruitment_id = localStorage.entryFlag;
              localStorage.removeItem('entryFlag');
              window.location.href = '/recruitments/' + recruitment_id + '/entry/';
              return;
            }
          }
        
          if (tempolary) {
              alert('保存しました');
              navigate('/mypage/');
              return;
          }
        //   navigate('/mypage/profile2/');
          window.location.href = '/mypage/profile2/?next=true';
        } else if(putregion.error != null) {
            setLoadScreen(false);
            navigate('/error?code=500');
        }
      }, [putregion]);
      
      function submit (e: any, tempolary1 = false) {
        setEdited(false);
        if(!tempolary1) {
            e.preventDefault();
        }
              
        if(resourceId !== null) {
            const data = {
                applicant: {
                    face_photo: face_photo,
                    family_name: family_name,
                    first_name: first_name,
                    family_name_kana: family_name_kana,
                    first_name_kana: first_name_kana,
                    birthday: birthday,
                    gender_id: gender,
                    postcode: postCode,
                    prefecture_id: prefectureId,
                    city_id: cityId,
                    address: address,
                    mobile_number: mobile_number,
                    educational_background: {
                        final_education_id: selectedFinalEducationId,
                        graduation_date: selectedGraduationDate,
                        new_graduate: isNewGraduate,
                    },
                    navigation_book: applicant.navigation_book
                }
            }
            settempolary(tempolary1);
            setLoadScreen(true);
            dispatch(fetchPutRegionRequest(resourceId, data));
        }
        
    }

    function closeBirthModel () {
        currentSlideNumber = 0;
        setBirthdayOpen(false);
        setCurrentSlide(0);
    }

    function openBirthday () {
        setEdited(true);
        setBirthdayOpen(true);
        getYears();
    }

    function chooseYear(e: any) {
        setGraduationModalSlide(1);
        setCurrentSlide(1);
        setGraduationYear(e.target.value); 
    } 

    function chooseMonth(e: any) {
        setIsGraduationDateModal(false);
        setGraduationMonth(e.target.value);
        setSelectedGraduationDate(`${graduationYear}-${e.target.value}`);
        setGraduationModalSlide(0);
        setCurrentSlide(0);
    } 

    function clear() {
        setSelectedFinalEducationId(null);
        setSelectedFinalEducationName('');
        closeModalFinalEducation();
        setGraduationYear(null);
        setGraduationMonth(null);
        setSelectedGraduationDate(null);
    }

    function prev() {
        setGraduationModalSlide(0);
        setCurrentSlide(0);
    }

    function completeOngoing(year: any, month: any) {
        setGraduationYear(year);
        setGraduationMonth(month);
    }

    function filteredYears() {
        let start = null;
        let end = null;
        if (baseMonth !== null) {
            if (baseMonthThanAgo) {
                start = Number(baseMonth.split('-')[0]);
                end = maxYear;
            } else {
                start = minYear;
                end = Number(baseMonth.split('-')[0]);
            }
        } else {
            start = minYear;
            end = maxYear;
        }
        const years = [];
        const count = end - start;
        for (let i = 0; i <= count; i++) {
            years.push(end - i);
        }
        setFilterYears(years);
        filteredMonths();
    }

    function filteredMonths () {
        const months = [];
        let start = 1;
        let end = 12;
        if (baseMonth) {
          if (year == dayjs().year()) {
            end = dayjs().month() + 1;
          } else if (baseMonth && year == baseMonth.split('-')[0]) {
            if (baseMonthThanAgo) {
              start = Number(baseMonth.split('-')[1]);
            } else {
              end = Number(baseMonth.split('-')[1]);
            }
          }
        }
        if (onlyPast && year == dayjs().year()) {
          end = dayjs().month() + 1;
        }
        for (let i = start; i <= end; i++) {
          months.push(i);
        }
        setFilterMonths(months);      
      }

      function chooseGender(e: any) {
        setEdited(true);
        setGender(e.target.value);
      }

      function onChangeFamilyNameKana(e: any) { 
        setEdited(true);
        const kanaValidate = new RegExp('^([ァ-ン]|ー)+$');  
        setFamilyNameKana(e.target.value);
        if(e.target.value === '') {
            setErrorFamilyNameKana(true);
        } else {
            if(e.target.value.length > 100) {
                setErrorFamilyNameKanaMaxLength(true);
                setErrorFamilyNameKana(true);
            } else {
                setErrorFamilyNameKanaMaxLength(false);
                setErrorFamilyNameKana(false);
            }
            if(kanaValidate.test(e.target.value)) {
                setErrorFamilyNameKanaCheck(false);
                setErrorFamilyNameKana(false);
            } else {
                setErrorFamilyNameKanaCheck(true);
                setErrorFamilyNameKana(true);
            }
        }        
      }

      function onChangeFirstNameKana(e: any) {  
        setEdited(true);
        const kanaValidate = new RegExp('^([ァ-ン]|ー)+$'); 
        setFirstNameKana(e.target.value);     
        if(e.target.value === '') {
            setErrorFirstNameKana(true);
        } else {
            if(e.target.value.length > 100) {
                setErrorFirstNameKanaMaxLength(true);
                setErrorFirstNameKana(true);
            } else {
                setErrorFirstNameKanaMaxLength(false);
                setErrorFirstNameKana(false);
            }
            if(kanaValidate.test(e.target.value)) {
                setErrorFirstNameKanaCheck(false);
                setErrorFirstNameKana(false);
            } else {
                setErrorFirstNameKanaCheck(true);
                setErrorFirstNameKana(true);
            }
        }        
      }

      function onChangePostCode(e: any) {     
        setEdited(true);   
        setPostCode(e.target.value);
        if(e.target.value === '') {
            setErrorPostCode(true);
        } else {            
            if(!/[0-9]/.test(e.target.value)){
                setPostCodeNumeric(true);
                setErrorPostCode(true);
            } else {
                setPostCodeNumeric(false);
                setErrorPostCode(false);
            }
            if(e.target.value.length >= 7) {
                setPostCodeMaxLength(true);
                setErrorPostCode(true);
            } else {
                setPostCodeMinLength(true);
                setErrorPostCode(true);
            }
            setErrorPostCode(false);
        }  
      }

      function onChangeMobileNumber(e: any) {
        setEdited(true);
        setMobileNumber(e.target.value);
        if(e.target.value === '') {
            setErrorMobileNumber(true);
        } else {
            if(e.target.value.length > 11) {
                setMobileMaxLengthError(true);
                setErrorMobileNumber(true);
            }
            if(e.target.value.length < 10) {
                setMobileMinLengthError(true);
                setErrorMobileNumber(true);
            } else {                
                setMobileMinLengthError(false);
                setErrorMobileNumber(false);
            }
            if(!/[0-9]/.test(e.target.value)){
                setMobileNumericError(true);
                setErrorMobileNumber(true);
            }else{
                setMobileNumericError(false);
                setErrorMobileNumber(false);
            }
        }
      }
      const handleFamilyName = (event: any ) => {
        setEdited(true);
        setFamilyName(event.target.value);
      };
      
      const handleFirstName = (event: any) => {
        setEdited(true);
        setFirstName(event.target.value);
      };

      const handleSetAddress = (event: any) => {
        setEdited(true);
        setAddress(event.target.value);
      };

    return (
      <main id="mypage">
        <MypageSideBar resource={resourceId} familyName = {familyName}  currentList="ApplicantList" />
        {loadScreen ? <Loader /> : <></>}
        <section id="mypage_inner">
            <ProfileIndex authenticate={authenticate} navigation_book_url={navigation_book}/>
            <ProfileTab ref={refs} index={1} />
            <form role="profile" className="profile wrapper">
                <div className="cont1">
                <h3 className="ttl">プロフィール</h3>
                <p className="ex_info">※ <span className="required">必須</span>が付いている項目はエントリーに必要な情報です。 </p>
                <div className="wrapper">
                    <table>
                    <tbody className="other">
                        <tr>
                        <th>顔写真<span className="required">必須</span>
                        </th>
                        <td className="photo">
                            <div className="img_box">
                                <img src={face_photo === null ? "/assets/img/company/img02.jpg" : face_photo} alt=""/>
                            </div>
                            <p className="btn type3">
                            <label>
                                <input type="file" className="btn type3" ref={fileInputRef} onChange={setImage}/>アップロード </label>
                            </p>
                            <p className="file_status">{ face_photo !== null ? 'アップロード済' : 'ファイルを選択してください' }</p>
                        </td>
                        </tr>
                        <tr>
                        <th>氏名<span className="required">必須</span>
                        </th>
                        <td className="name">
                            <label>姓<input type="text" name="name1" value={family_name || ''} onChange={(event) => handleFamilyName(event)}/>
                            </label>
                            <label>名<input type="text" name="name2" value={first_name || ''} onChange={(event) => handleFirstName(event)}/>
                            </label>
                            <p className="note wide">※外国籍の方はカタカナ/英字でも可</p>
                        </td>
                        </tr>
                        <tr>
                        <th>氏名<small>（フリガナ</small>
                            <span className="required">必須</span>
                        </th>
                        <td className="name">
                            <label>姓<input type="text" name="kana1" className={errorFamilyNameKana ? "error" : ""} value={family_name_kana || ''} onChange={(e) => onChangeFamilyNameKana(e)}/>
                            </label>
                            <label>名<input type="text" name="kana2" className={errorFirstNameKana ? "error" : ""} value={first_name_kana || ''} onChange={(e) => onChangeFirstNameKana(e)}/>
                            </label>
                            {errorFamilyNameKana  || errorFirstNameKana  ?
                                <div className="error_box">
                                    {errorFamilyNameMaxlength || errorFirstNameMaxlength ?
                                        <p className="error_msg">氏名はそれぞれ{ family_name_kana}文字以下で入力してください</p> 
                                    : <></>}
                                    {errorFamilyNameKanaCheck  || errorFirstNameKanaCheck ?
                                        <p className="error_msg">氏名（フリガナ）は全角カタカナで入力してください</p>
                                    : <></>}
                                </div>
                            : <></>}
                        </td>
                        </tr>
                        <tr>
                        <th>生年月日<span className="required">必須</span>
                        </th>
                        <td className="birthday">
                            <div className="select_cont">
                            <p className="select comp"><a onClick={openBirthday} title="ポップアップが立ち上がります。TABキーを押して選択してください。" className="select">{ formatYMD(birthday) || '選択してください' }</a></p>
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <th>年齢</th>
                        <td>{calcAge()}</td>
                        </tr>
                        <tr>
                        <th>性別<span className="any">任意</span>
                        </th>
                        <td>
                            <div className="radio">
                            <label>
                                <input type="radio" name="gender" value="1" checked={1 == gender} onChange={(e)=>chooseGender(e)}/>男</label>
                            <label>
                               <input type="radio" name="gender" value="2" checked={2 == gender} onChange={(e)=>chooseGender(e)}/>女</label>
                            <label>
                                <input type="radio" name="gender" value="3" checked={3 == gender} onChange={(e)=>chooseGender(e)}/>回答しない</label>
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <th>郵便番号<small>（半角数字）</small>
                            <span className="required">必須</span>
                        </th>
                        <td className="zip">
                            <label>
                                <input type="text" name="zip" maxLength={7} pattern="[0-9]*" className={errorPostCode ? 'error': ''} value={postCode || ''} onChange={(e) => onChangePostCode(e)}/>
                                <button type="button" className="btn type3" onClick={(e) => {e.preventDefault();setBtn(true);}}>住所検索</button>
                            </label>
                            {/* postcode error */}
                            {errorPostCode ?
                                <div className="error_box">
                                    {postCodeNumeric ?
                                        <p className="error_msg">郵便番号は数字のみで入力してください</p> 
                                    : <></>}
                                    {postCodeMaxLength ?
                                        <p className="error_msg">郵便番号は{ postCodeMaxLen }桁で入力してください</p>
                                    : <></>}
                                </div>
                            : <></>}
                            <p className="note">※ハイフン不要、半角数字</p>
                        </td>
                        </tr>
                        <tr>
                        <th>住所<span className="required">必須</span>
                        </th>
                        <td className="address nest">
                            <div className="select_cont nest_child">
                            <h4>都道府県 市・区</h4>
                            <p>{prefectureName ? prefectureName : applicantPrefectureName}{cityName ? cityName : applicantCityName}</p>
                            </div>
                            <div className="nest_child" id={classErr2}>
                            <label>
                                <h4>番地</h4>
                                <input type="text" name="address3" value={address || ''} onChange={(event) => handleSetAddress(event)}/>
                                { classErr2.length !== 0 ?
                                    <div className="error_box">
                                    <p className="error_msg">番地を入力してください</p> 
                                    </div>
                                :<></>}
                            </label>
                            </div>
                            <p className="note wide">※面接が確定次第、企業へ公開されます</p>
                        </td>
                        </tr>
                        <tr>
                        <th>メールアドレス<span className="required">必須</span>
                        </th>
                        <td className="email">
                            <p>{email}</p>
                            <p className="note wide">※面接が確定次第、企業へ公開されます</p>
                        </td>
                        </tr>
                        <tr>
                        <th>電話番号<small>（半角数字）</small>
                            <span className="required">必須</span>
                        </th>
                        <td className='tel' id={classErr}>
                            <div>
                            <label className="mb5">
                                <input type="tel" name="tel" maxLength={11} minLength={10} style={errorMobileNumber ? {background : "#ffd9d9"} : {}} className={errorMobileNumber ? "error" : ""} value={mobile_number || ''} onChange={(e) => onChangeMobileNumber(e)}/>
                            </label>
                            </div>
                            {/* mobile_number error */}
                            {mobile_number ?
                                <div className="error_box">
                                    {mobileMaxLengthError || mobileMinLengthError ?
                                        <p className="error_msg">電話番号は{ mobileMinLen }桁以上{ mobileMaxLen }桁以下で入力してください</p> 
                                    : <></>}
                                    {mobileNumericError ?
                                        <p className="error_msg">電話番号は数字のみで入力してください</p>
                                    : <></>}
                                </div>
                            : <></>}
                            <p className="note wide"> ※ハイフン不要、半角数字 <br/> ※面接が確定次第、企業へ公開されます </p>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
                <div className="cont2">
                <h3 className="ttl">学歴または学校に関する情報</h3>
                <div className="wrapper">
                    <table>
                    <tbody className="other">
                        <tr>
                        <th>就職カテゴリー<span className="required">必須</span>
                        </th>
                        <td>
                            <div className="radio">
                                <label>
                                    <input
                                        type="radio"
                                        name="school7"
                                        value='true'
                                        checked={isNewGraduate === true}
                                        onChange={() => { setNewGraduate(true);setEdited(true)}}
                                    />
                                    新卒
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="school7"
                                        value='false'
                                        checked={isNewGraduate === false}
                                        onChange={() => { setNewGraduate(false);setEdited(true)}}
                                    />
                                    中途
                                </label>
                            </div>
                        </td>
                        </tr>
                        <tr>
                            <th>最終学歴<span className="required">必須</span>
                                <br/>
                                <small>（新卒者は現在の在籍校）</small>
                            </th>
                            <td>
                                <p className="select"><a onClick={openModalFinalEducation}  title="ポップアップが立ち上がります。TABキーを押して選択してください。" >{ findValue(selectedFinalEducationId, 'final_educations') || '未登録' }</a></p>
                            </td>
                        </tr>
                        <tr>
                            <th>卒業・卒業見込み年度<span className="required">必須</span>
                            </th>
                            <td className="graduation">
                                <p className="select"><a onClick={openModalGraduationDate} title="ポップアップが立ち上がります。TABキーを押して選択してください。" >{ formatYM(selectedGraduationDate) || '選択してください' } </a></p>
                            </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
                <div className="btn_box">
                    <button type="button" onClick={(e) => submit(e, true)} className="btn type3">一時保存する</button> 
                    <button type="submit" onClick={(e) => submit(e, false)} className="btn type3 orange">保存して次へ         
                        <img src="/assets/img/mypage/arrow02.svg" alt=""/>
                    </button>
                </div>
            </form>
        </section>

        {/* BIRTHDAY MODAL */}
        {isBirthdayOpen ?
        <div className="modal_box">
            <div className="modal_inner">
                <h4>生年月日</h4>
                {currentSlideNumber > 0 ?
                    <p id="btn_prev">戻る</p>
                :<></>}   
                <form>
                <h5 className="modal_ttl">{dataSetNames[currentSlideNumber]}を選択してください</h5>
                <div className="slider">
                    
                    { birthModalSlide === 0 ?
                        <div className="slider_item">
                            {years.map((y: any) => (
                                <label key={`birth-year-${y}`}>
                                    <input
                                        type="radio"
                                        name="year"
                                        defaultChecked={y == year}
                                        value={y}
                                        onClick={onChangeInputBirthModal}
                                    />
                                    <span>{ y } 年</span>
                                </label>
                            ))}    
                        </div>
                    :<></>}  
                    {birthModalSlide === 1 ?
                        <div className="slider_item">                    
                            {months.map((m: any) => (
                                <label key={`birth-month-${m}`}>
                                    <input
                                        type="radio"
                                        name="month"
                                        defaultChecked={m == month}
                                        value={m}
                                        onClick={onChangeInputBirthModal}
                                    />
                                    <span>{ m } 月</span>
                                </label>
                            ))}    
                        </div>
                    :<></>}  
                    {birthModalSlide === 2 ?
                        <div className="slider_item"> 
                            {days.map((d: any) => (
                                <label key={`birth-day-${d}`}>
                                    <input
                                        type="radio"
                                        name="date"   
                                        defaultChecked={d == day}
                                        value={d}              
                                        onClick={onChangeInputBirthModal}
                                    />
                                    <span> { d }  日</span>
                                </label>
                            ))}    
                        </div>
                    :<></>}
                </div>
                </form>
                <p className="close" onMouseUp={closeBirthModel}><span className="material-icons">close</span></p>
            </div>
            <div className="overlay" onMouseUp={closeBirthModel}></div>
        </div>
        : null }


        {/* FINALEDUCATION MODAL */}
        {isFinalEducationModal ?
            <div className="modal_box selectRadio1">
                <div className="modal_inner">
                <h4>{ finalEducationTitle }</h4>
                <form>
                    <h5 className="modal_ttl">{ finalEducationDataSetName }を選択してください</h5>
                    <div className="slider_item">
                        <label className="column3" onMouseUp={clear}>
                            <input type="radio" name="radio1" defaultChecked={null == selectedFinalEducationId} onClick={clear}/>
                            <span>なし</span>
                        </label>
                        {finalEducationData.map((d: any, index: any) => (
                            <label key={index}>
                                <input type="radio" name={d.name} defaultChecked={d.id == selectedFinalEducationId} value={d.id} onClick={() =>{ setSelectedFinalEducationId(d.id); closeModalFinalEducation()}}/>
                                <span>{ d.name }</span>
                            </label>
                        ))} 
                    </div>
                </form>
                <p className="close" onKeyUp={closeModalFinalEducation} onMouseUp={closeModalFinalEducation}>
                    <span className="material-icons">close</span>
                </p>
                </div>
                <div className="overlay"onKeyUp={closeModalFinalEducation} onMouseUp={closeModalFinalEducation}></div>
            </div>
        : null }

        {/* GRADUATION DATE MODAL */}
        {isGraduationDateModal ?
        <div className="modal_box">
            <div className="modal_inner">
                <h4>{ graduationTitle }</h4>
                    {currentSlide > 0 ?
                        <p id="btn_prev" onClick={prev} className='prev'>戻る</p>
                    : null }
                <form>
                    <h5 className="modal_ttl">{ graduationDataSetNames[currentSlide] }を選択してください</h5>
                    { graduationModalSlide === 0 ?
                        <div className="slider_item">
                            { enableNone ?
                            <label className="column3">
                                <input type="radio" name="year" onClick={(e) => completeOngoing(9999,12)} />
                                <span>終了月なし（継続中）</span>
                            </label>
                            :<></>}
                            {filterYears && filterYears.map((y: any) => (
                                <label key={y}>
                                    <input type="radio" name="year" defaultChecked={y == graduationYear} value={y} v-model="year" onClick={chooseYear}/>
                                    <span>{ y } 年</span>
                                </label>                                
                            ))}    
                        </div>
                    :<></>} 
                    { graduationModalSlide === 1 ?
                        <div className="slider_item">                                                                           
                            {filterMonths && filterMonths.map((m: any) => (
                                <label key={m}>
                                    <input type="radio" name="month" id={'date_' + m} defaultChecked={m == graduationMonth} value={m} onClick={chooseMonth} />
                                    <span>{ m } 月</span>
                                </label>                              
                            ))}    
                        </div>
                    :<></>} 
                        
                    <div className="slider_item">
                    </div>
                </form>
                <p className="close" onKeyUp={closeModalGraduationDate} onMouseUp={closeModalGraduationDate}><span className="material-icons">close</span></p>
                </div>
            <div className="overlay" onKeyUp={closeModalGraduationDate} onMouseUp={closeModalGraduationDate}></div>
        </div>
        : null }

      </main>
    );
}

