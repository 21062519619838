import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { fetchInvitationFailure, fetchInvitationSuccess } from "../../actions/invitationActions/invitationActions";
import { invitationTypes } from "../../ActionTypes/invitationTypes";


var getInvitation = (id:any) => axios.get(API_BASE_ADDRESS + '/add_recruiter', {headers: { Accept: 'application/json'}}); 

function* fetchInvitationSaga(id: any) {
  try {
    const response: AxiosResponse = yield call(getInvitation, id.id);
    yield put(
      fetchInvitationSuccess({
        invitation: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchInvitationFailure({
        error: e.message
      })
    );
  }
}

function* InvitationSaga() {
  yield takeLatest(invitationTypes.FETCH_INVITATIONS_REQUEST, fetchInvitationSaga);
}

export default InvitationSaga;
