import React, { useEffect, useState, CSSProperties  } from 'react';
import MypageSideBar from '../../../../components/MypageSideBar/MypageSideBar';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redax/reducers/rootReducer';
import { fetchLoginRequest } from '../../../../redax/actions/loginActions/loginActions';
import { useParams } from 'react-router-dom';
import { fetchApplicationsRequest } from '../../../../redax/actions/applicationsActions/applicationsActions';
import { fetchMessagesPostRequest } from '../../../../redax/actions/messagesPostActions/messagesPostActions ';
import { useNavigate } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";
import Loader from '../../../loader/loader';
import dayjs from 'dayjs';
import './messages.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import ja from 'dayjs/locale/ja';

const override: CSSProperties = {
    display: "inline-block",
    position: "absolute",
    margin: "20% auto 40%",
    // margin: "0 auto",
    border: "3px outset",
    borderColor: "silver",
    // z-index: "0",
    zIndex: "100"
  };

function Messages() {
    const [familyName, setFamylyName] = useState(null);
    const [resourceId, setResourceId] = useState(null);
    const [application, setApplication] = useState<any>([]);
    const [applicantAppealItem, setApplicantAppealItem] = useState<any>([]);
    const [recruitmentAppealItem, setRecruitmentAppealItem] = useState<any>([]);
    const [data, setData] = useState<any>([]);
    const [applicant, setApplicant] = useState<any>([]);
    const [messages, setMessages] = useState<any[]>([]);
    const [candidates, setCandidates] = useState<any[]>([]);
    const [candidatesNew, setCandidatesNew] = useState<any[]>([]);
    const [applicantHandicap_types, setApplicantHandicap_types] = useState<any[]>([]);
    const [sendScheduleTabSelect, setSendScheduleTabSelect] = useState(false);
    const [isOpen, setModalOpen] = useState(false);
    const [errorSupTime, setErrorSuppTime] = useState(false);
    const [proposal, setProposal] = useState(false);
    const [startDate, setStartDate] = useState<any>();
    const [startTimeHour, setStartTimeHour] = useState(null);
    const [startTimeMinute, setStartTimeMinute] = useState(null);
    const dispatch = useDispatch();
    let param1 = '';
    const [over, setOver] = useState('');
    const [message, setMessage] = useState('');
    const [supp_time, setSuppTime] = useState('');
    let [loading, setLoading] = useState(true);
    let [color, setColor] = useState("#ffffff");
    const loginData = useSelector((state: RootState) => state.login);  
    const applications = useSelector((state: RootState) => state.applications);
    const messagespost = useSelector((state: RootState) => state.messagespost);
    const { id } = useParams();
    const navigate = useNavigate();
    let nowDate = dayjs().format('YYYY-MM-DD');
    const [loadScreen, setLoadScreen] = useState(true);
    const [curHour, setCurHour] = useState('');
    const [curMinute, setCurMinute] = useState('');
    const [firstLoad, setFirstLoad] = useState(true);

    useEffect(() => {
        setLoading(true);
        // dispatch(fetchLoginRequest('', '', ''));
    }, [dispatch]);

    useEffect(() => {
        if (loginData.pending) {
            if(!loginData.login.is_init_complete) {
                navigate('/recruiter/init/');
            }
          setResourceId(loginData.login.resource.id);
          setFamylyName(loginData.login.resource.family_name); 
        //   setRecruiter(loginData.login.resource);
          if(id !== null) {
            // param = '/applications/' + id  
            // dispatch(fetchApplicationsRequest(param));        
            param1 = '/applications/' + id + '/messages';
            dispatch(fetchApplicationsRequest(param1, 'get', null));
          }
        }       
        setLoading(false);
    }, [dispatch, loginData.pending]);

    useEffect(() => {
        if (applications.pending && firstLoad) {
            setApplication(applications.applications.application);
            setApplicant(applications.applications.applicant);
            setMessages(applications.applications.messages);
            // setApplicantAppealItem(applications.applications.application.applicant_appeal_item);
            // setRecruitmentAppealItem(applications.applications.application.recruitment_appeal_item);
            if(applications.applications.applicant.handicap_types) {
                setApplicantHandicap_types(applications.applications.applicant.handicap_types);
            }
            setLoadScreen(false);
            setFirstLoad(false);
            const queryParams = new URLSearchParams(window.location.search);
            if (queryParams.get('send_schedule')) {
                let ss = queryParams.get('send_schedule');
                setSendScheduleTabSelect(ss != null && ss == 'true' ? true : false);
                const element = document.getElementById("cont_box");
                if(element != null) {
                    element.scrollTo(0, element.scrollHeight);
                }
          
            }
            // テンプレートがある場合
            if (queryParams.get('template')) {
                let mes = queryParams.get('template');
                setMessage(mes !== null && mes !== 'null' ? mes.replace("\n", '<br>') : '');
                const element = document.getElementById("input_box");
                if(element != null) {
                    element.scrollIntoView();
                }
            }
            messagesScroll()
            setOver(charaLimit(message, 200));
            setCandidates([1]);
        } else if(applications.error != null) {
            setLoadScreen(false);
            navigate('/error?code=500');
        }
    }, [applications, firstLoad]);
    
    function logout() {
        if (localStorage.entryFlag) {
          localStorage.removeItem('entryFlag')
        }
        if (localStorage.jobParachannelAuthToken) {
          localStorage.removeItem('jobParachannelAuthToken')
          navigate('/');
          window.location.reload();
        }
    }

    function messagesScroll () {
        setTimeout(() => {
            const element = document.getElementById("view_box");
            if(element != null) {
                element.scrollTo(0, element.scrollHeight);
            }
        }, 1);
    }

    function charaLimit (inputText: any, limit: any) {
        if (typeof inputText != "string") { return '' }
        return inputText.length > limit ? (inputText.length - limit) + '文字オーバー' : ''
    }

    function formatDate (value: any) {
        dayjs.locale(ja);
        return dayjs(value).locale(ja).format('YYYY.MM.DD (ddd) HH:mm');
    }

    function isSelf () {
        return function (value: any) {
            return (value === 'Company');
        }
    }

    function calcType () {
        return function (value: any) {
            switch (value) {
                case 1: return '面接';
                case 2: return '職場体験・実習';
                default: return null;
            }
        }
    }

    function sendMessage () {
        if(message && !over) {
            setLoadScreen(true);
            dispatch(fetchMessagesPostRequest(id, {
                application_id: id,
                message: message }));
                setTimeout(function(){                        
                    setMessage('');
                    setLoadScreen(false);
                }, 150);
        }
    }

    useEffect(() => {
        if(messagespost !== undefined && messagespost.pending) {
            setMessages(messagespost.messages.messages);
            setLoadScreen(false);
            messagesScroll();
        } else if(messagespost.error != null) {
            setLoadScreen(false);
            navigate('/error?code=500');
        }  
    }, [messagespost, messages]);

    function changeMessage(e: any) {
        setMessage(e.target.value);
        setOver(charaLimit(e.target.value, 200));
    }

    function scheduleInterview() {
        const data = {
            application_id: id,
            candidate_type_id: 1,
            candidates: candidatesNew,
            supp_time: supp_time
        }
        dispatch(fetchMessagesPostRequest(id, data));
        setCandidates([]);
        setSuppTime('');
    }

    function cancel() {
        setCandidates([]);
    }

    function addSelector (index: any) {
        let c = [];
        for(let i = 0; i < candidates.length; i++) {
            c.push(candidates[i]);
        }
        c.push(1);
        setCandidates(c);
    }

    function delSelector (index: any) {
        let c = [];
        for(let i = 0; i < candidates.length; i++) {
            c.push(candidates[i]);
        }
        c.splice(index, 1);
        setCandidates(c);
        let c2 = [];
        for(let i = 0; i < candidatesNew.length; i++) {
            c2.push(candidatesNew[i]);
        }
        c2.splice(index, 1);
        setCandidatesNew(c2);
    }

    function hourList() {
        return Array(24).fill(0).map((_, i) => ('00' + i).slice(-2));
    }

    function minuteList(): string[] {
        return Array(4).fill(0).map((_, i) => ('00' + (i * 15)).slice(-2));
    }

    function changeSuppTime(e: any) {
        setSuppTime(e.target.value);
    }

    function changeDate(e: any, index: any) {
        let c = [];
        for(let i = 0; i < candidatesNew.length; i++) {
            c.push(candidatesNew[i]);
        }
        if(e.target.value < nowDate) {
            setStartDate(nowDate);
        } else {
            setStartDate(e.target.value);
        }
        setCurHour('00');
        setCurMinute('00');
        if(candidatesNew.length == 0 && e.target.value != null && e.target.value != '') {
            setCandidatesNew([e.target.value + 'T00:00:00']);
        } else {
            if(e.target.value == null || e.target.value == '') {
                c.splice(index, 1);
                setCandidatesNew(c);
            } else if(candidatesNew[index] != null) {
                c[index] = e.target.value + 'T00:00:00';
                setCandidatesNew(c);
            } else {
                c.push(e.target.value + 'T00:00:00');
                setCandidatesNew(c);
            }
        }
    }

    function changeTime(e: any, index: any) {
        let c = [];
        for(let i = 0; i < candidatesNew.length; i++) {
            c.push(candidatesNew[i]);
        }
        setStartTimeHour(e.target.value);
        c[index] = c[index].split('T')[0] + 'T' + e.target.value + ':' + c[index].split('T')[1].split(':')[1] + ':00';
        setCandidatesNew(c);
    }

    function changeMinute(e: any, index: any) {
        let c = [];
        for(let i = 0; i < candidatesNew.length; i++) {
            c.push(candidatesNew[i]);
        }
        setStartTimeMinute(e.target.value);
        c[index] = c[index].split(':')[0] + ':' + e.target.value + ':00';
        setCandidatesNew(c);
    }

    function openModal(v: any, i: any) {
        setProposal(i);
        setModalOpen(true);
    }

    function closeModal() {
        setModalOpen(false);
    }

    function complete() {
        if (candidatesNew.length == 0) {
            window.alert('未入力の項目があります');
            return;
        }
        for(let i = 0; i < candidatesNew.length; i++) {
            if(candidatesNew[i].length < 19) {
                window.alert('未入力の項目があります');
                return;            
            }
        }
        if(window.confirm('一度送信すると取り消せません。よろしいですか？')) {
            scheduleInterview();
            closeModal();
        }
    }

    return (
        <main id="company">            
            <MypageSideBar resource={resourceId} familyName = {familyName}  currentList="RecruiterList" />
            {loadScreen ? <Loader /> : <></>}
            <section>
                <ul className="bread_crumb">
                    <li><a>トップ</a></li>
                    <li><a>企業様マイページ</a></li>
                    <li><a>応募管理 / メッセージ</a></li>
                </ul>
                <div className="ttl_box">
                    <h2 className="ttl">応募管理 ＞ メッセージ</h2>
                    <ul>
                        <li>
                            <a href="#" onClick={logout}>
                                <img src="/assets/img/company/icon02.svg" alt="" />ログアウト
                            </a>
                        </li>
                    </ul>
                </div>

                <div className="message cont2">
                    <div className="ttl_box">
                            <a href={'/recruiter/applications/' + id +'/'} className="ttl">
                                <p>応募者詳細</p>
                            </a>
                            <a href={'/recruiter/applications/' + id +'/messages'} className={  application.unread ? 'new_icon ttl active' : 'ttl active'}>
                                <p>メッセージ</p>
                            </a>
                </div>

                <div id="cont_box" className="cont_box">
                    <div className="name_box">
                        {/* <img src={applicant.face_photo } alt="" /> */}
                        <img src={applicant.face_photo } alt="applicant.family_name + applicant.first_name" />
                        <div>
                            <h3>{ applicant.family_name } { applicant.first_name }
                                （{ applicant.family_name_kana } { applicant.first_name_kana }）
                                {applicantHandicap_types && applicantHandicap_types.map((data: any, index: any ) => (
                                    <span className="tag" key={index}>{ data }</span>
                                ))} 
                            </h3>
                        {/* <!-- <p>最終ログイン日：1週間以内</p> --> */}
                        </div>
                    </div>
                    <div className="message_box">
                        <div className="view_box" id="view_box">
                            {messages  && messages.map((sms: any ) => (
                            <div className={sms.sender === 'Company' ? 'right' : 'left'}  key={sms.id}>
                                <p className="date">{formatDate(sms.created_at)}</p>
                                {sms.candidates ?
                                    <div>
                                        <p>
                                            面接・職場見学日時について打診しました
                                        </p>
                                        <ul>
                                            <br/>
                                         {sms.candidates && sms.candidates.map((mc: any, index: any ) => (
                                                <li key={index}> 
                                                     日程候補{index + 1}. { formatDate(mc) } ～
                                                </li>
                                            ))} 
                                            {sms.supp_time ?
                                            <p>
                                            想定実施時間 { sms.supp_time }分
                                            </p>
                                            : <></>}
                                            <p><br/>※システムより自動で送信されています。</p>
                                        </ul>
                                    </div>
                                : <div className={ sms.is_auto_message_at_entry ? 'contents auto_message' : 'contents' }>{ sms.message }</div>}
                                {/* <div v-else className="contents" className="{ auto_message: m.is_auto_message_at_entry }">{{ m.message }}</div> */}
                            </div>
                            ))}
                        </div>
                        <div className="input_box" id="input_box">
                        <ul>
                            <li className={ !sendScheduleTabSelect ? 'active' : '' }>
                                <a className='tab-pointer' onClick={() => setSendScheduleTabSelect(false)}>メッセージ</a>
                            </li>
                            <li className={ sendScheduleTabSelect ? 'active' : '' }>
                                <a className='tab-pointer' onClick={() => setSendScheduleTabSelect(true)}>日程送信</a>
                            </li>
                        </ul>
                        {sendScheduleTabSelect ?
                        <div>
                            <p className='dispNone'>
                                候補日時：<br/>
                                {candidates  && candidates.map((i: any ) => (
                                    <span key={i}>
                                    { formatDate(i)} ～<br/>
                                    </span>
                                ))}
                                想定実施時間：{ supp_time }分<br/>
                            </p>
                            <a href="#" onClick={() => openModal(null, true)} className="btn type3">
                                <img src="/assets/img/company/icon17.png" alt=""/>
                                面接・職場見学の日程を調整する
                            </a>
                            <a onClick={scheduleInterview} className="btn type3 orange dispNone">候補日時を送信</a>
                            <a onClick={cancel} className="btn type3 gray dispNone">キャンセル</a>
                            <p className="note">※面接・職場見学の日程はこちらの「面接・職場見学の日程を調整する」の機能を使って確定することでステータスが変更となります。<br/>
                            したがって、テキストメッセージは使わずこちらの機能をお使いください。</p>
                        </div>
                        : 
                        <form>
                            <textarea name="textarea" placeholder="ここにメッセージ内容を入力（200文字以内）" v-model="message" value={message} onChange={(e) => changeMessage(e)}></textarea>
                            <p className="text_length_alert">{ over }</p>
                            <button onClick={sendMessage} type="button" className={ !message || over ? 'btn type3 disabled' : 'btn type3 over' }>送信</button>
                            <a href="/recruiter/schedules/" className="messages-schedule">スケジュールを確認する</a>
                        </form>}
                        
                        </div>
                    </div>
                    </div>
                </div>
                {isOpen ?
                <div className="modal_box">
                    <div className="modal_inner">
                    <h4>{ proposal ? '日程候補' : '日程' }を選んで下さい</h4>
                    <div className="change_box">
                        <ul className="candidate_list">
                            {candidates && candidates.map((i: any, index: any ) => (
                                <li>
                                <p className="date_ttl">
                                    日時候補{ index + 1 }
                                </p>
                                <div className="date_input_cont">
                                    <input type="date" className="date" value={startDate || '0000-00-00'} min={nowDate} onChange={(e) => changeDate(e, index)} />
                                    <select v-model="startTimeHour" disabled={candidatesNew[index] == null} className='modal-time' onChange={(e) => changeTime(e, index)}>
                                    <option value=""></option>
                                    {hourList().map((h: any) => (
                                        <option value={h} key={h} selected={curHour == h}>{ h }</option>
                                    ))}
                                    </select>
                                    <span>:</span>
                                    <select v-model="startTimeMinute" disabled={candidatesNew[index] == null} className='modal-time' onChange={(e) => changeMinute(e, index)}>
                                    <option value=""></option>
                                    {minuteList().map((m: any) => (
                                        <option value={m} key={m} selected={curMinute == m}>{ m }</option>
                                    ))}
                                    </select>
                                    <span>～</span>
                                    <div className="edit_cont">
                                        {candidates.length < 3 ?
                                            <span onClick={() => addSelector(index)}>
                                                <img src="/assets/img/mypage/add_icon_frame.svg" alt=""/>
                                            </span>
                                        : <></>}
                                        {candidates.length != 1 ?
                                            <span onClick={() => delSelector(index)}>
                                                <img src="/assets/img/mypage/del_icon_frame.svg" alt=""/>
                                            </span>
                                        : <></>}
                                    </div>
                                </div>
                                </li>
                            ))}
                        </ul>
                        <div className="radio_box">
                        <p className="ttl">想定実施時間</p>
                        <div className='main-supp-time'>
                            <input type="number" v-model="supp_time" value={supp_time} onChange={changeSuppTime} className={ errorSupTime ? 'error modal-supp-time' : 'modal-supp-time'} />分
                        </div>
                        </div>
                        {errorSupTime ? 
                        <p className="error_msg">半角数字で入力してください</p>
                        : <></>}
                    </div>
                    <p className="complete" onClick={complete}>候補日を送信</p>
                    <p className="close" onClick={closeModal}><FontAwesomeIcon icon={faX} /></p>
                    </div>
                    <div className="overlay" onClick={closeModal}></div>
                </div>
                : <></>}
            </section>
    </main>
    );
  }
  
  export default Messages;