import { useEffect, useState } from "react";
import MypageSideBar from "../../../components/MypageSideBar/MypageSideBar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redax/reducers/rootReducer";
import { fetchLoginRequest } from "../../../redax/actions/loginActions/loginActions";
import { useNavigate } from "react-router-dom";
import { fetchApplicantsRequest } from "../../../redax/actions/applicantsActions/applicantsActions";
import { fetchSettingsRequest } from "../../../redax/actions/settingsActions/settingsActions";

function Settings() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [familyName, setFamylyName] = useState(null);
    const [resourceId, setResourceId] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const [authenticate, setAuthenticate] = useState<any>([]);
    const [isActive, setIsActive] = useState(1);
    const [screen, setScreen] = useState(0);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const applicants = useSelector((state: RootState) => state.applicants);
    const settings = useSelector((state: RootState) => state.settings);

    useEffect(() => {
      if (!localStorage.getItem('jobParachannelAuthToken')) {
        navigate('/login');
      } 
    }, [navigate, dispatch]);
    const loginData = useSelector((state: RootState) => state.login);
    const emailChange = useSelector((state: RootState) => state.emailChange);

    useEffect(() => {
        if (loginData.pending) {
            if(!loginData.login.is_init_complete) {
              navigate('/mypage/init/');
            }
            setResourceId(loginData.login.resource.id);
            setFamylyName(loginData.login.resource.family_name);
            setAuthenticate(loginData.login.authenticate);
            if(loginData.login.is_active){
              setIsActive(1)
            } else {
              setIsActive(0)
            }
        }
      }, [loginData.pending]);

      function logout() {
        if (localStorage.entryFlag) {
          localStorage.removeItem('entryFlag')
        }
        if (localStorage.jobParachannelAuthToken) {
          localStorage.removeItem('jobParachannelAuthToken')
          navigate('/');
          window.location.reload();
        }
      }
      function openModal() {
        setModalOpen(true);
      }
      function closeModal() {
        setModalOpen(false);
      }
      function statusSwitching (num: any) {
        if (window.confirm("ステータスを変更しますか？")) {
          setIsActive(num)
          const data = { is_active: num };
          dispatch(fetchApplicantsRequest(loginData.login.resource.id, 'put', num));
        }
      };
      function submit() {
        if(emailError != '') {
          return;
        }
        dispatch(fetchSettingsRequest(email, 'applicant'));
      }

      useEffect(() => {
        if (settings.pending) {            
            setScreen(1);
        } else if(settings.error !== null) {
            setScreen(2);
        }
    }, [settings]);

      const handleChange = (event: any) => {
        const { value } = event.target;
        setEmail(value);
        validateEmail(value);
        if(value === ''){
          setEmailError('必須項目です')
        }
      };

      const validateEmail = (value: any) => {
        const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!regex.test(value)) {
          setEmailError('正しいメールアドレスの形式で入力してください');
        } else {
          setEmailError('');
        }
      };

return (
 <main  id="mypage">
    <MypageSideBar resource={resourceId} familyName = {familyName}  currentList="ApplicantList" />
    <section id="mypage_inner" className="settings_cont">
      <ul className="bread_crumb">
        <li><a href="/">トップ</a></li>
        <li><a href="/mypage">マイページ</a></li>
        <li><a>設定</a></li>
      </ul>
      <div className="ttl_box">
        <h2 className="ttl">設定</h2>
        <ul>
          <li><a href="#" onClick={logout}><img src="/assets/img/company/icon02.svg" alt=""/>ログアウト</a></li>
        </ul>
      </div>
      <div className="">
        <div className="setting">
          <h2>登録メールアドレス</h2>
          <div className="info">
            <div className="set_input">
              <p>{authenticate.email }</p>
            </div>
            <a href="#" className="set_btn" onClick={openModal}>
              変更する
            </a>
          </div>
        </div>
        {isModalOpen ?
          <div className="modal_box interview_modal"  >
            <div className="modal_inner">
              {screen === 0 ? 
              <>
              <h4>メールアドレスの変更</h4>
              <p className="read">
                変更したいメールアドレスを入力して送信を押してください。<br/>新しいメールアドレス宛に確認メールが送信されます。
              </p>
              <div className="change_box">
                <input type="email" name="email" required onChange={handleChange}/>
                {emailError && <div>
                  <span> {emailError}</span>
                </div>}
              </div>
              <div className="btn_cont">
                <a href="#" onClick={submit} className="set_btn">
                  送信する
                </a>
              </div>
              
              </> : 
              <>
              <h4>メールアドレス変更申請完了</h4>
              <p className="read end">ご入力いただいたメールアドレス宛に<br className="sp_only"/>確認メールを送信しました。<br/>メール内容をご確認いただき、変更を実行してください。</p>
              </>}
              <p className="close" onClick={closeModal} ><span className="material-icons">close</span></p>
            </div>
            <div className="overlay" onClick={closeModal}></div>
          </div>
         : <></>}
            <div className="setting">
              <h2>ステータス変更</h2>
              <div className="info status">
                <div className="set_input">
                  <p>ステータスをOFFにすると、お仕事探しをおやすみします。</p>
                </div>
                <div className="switch">
                  <a href="#" className={isActive === 1 ? 'active' : ''} onClick={(e) => { e.preventDefault(); statusSwitching(1); }}>ON</a>
                  <a href="#" className={isActive === 0 ? 'active' : ''} onClick={(e) => { e.preventDefault(); statusSwitching(0); }}>OFF</a>
                </div>
              </div>
            </div>
      </div>
      <div className="withdrawal">
        <a href="/contact/">退会をご希望の方</a>
      </div> 

    </section>
 </main>
);

}

export default Settings;