import { RootState } from '../../redax/reducers/rootReducer';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRecruitmentsRequest } from "../../redax/actions/recruitmentsActions/recruitmentsActions";
import { fetchRegionsRequest } from "../../redax/actions/regionsActions/regionsActions";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Keyboard, Navigation } from "swiper/modules";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './index.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import Column from '../../components/column/column';
import { fetchLoginRequest } from '../../redax/actions/loginActions/loginActions';
import { BrowserView, MobileView, isBrowser, isDesktop, isMobile } from 'react-device-detect';

function Indexs() {
    const dispatch = useDispatch();
    const { recruitments , pending} = useSelector(
      (state: RootState) => state.recruitments
    );

    const { regions  } = useSelector(
        (state: RootState) => state.regions
    );
    const loginData = useSelector((state: RootState) => state.login);

    const [ prefectureId , setprefectures] = useState('');
    const [selectJobCategoryTtypes , setjob_category_types] = useState('');
    const [selectEmploymentStatuses , setemployment_statuses] = useState('');
    const [searchDisabled, setSearchDisabled] = useState(true);
    const [isLogin, setLogin] = useState(false);
    const navigate = useNavigate();
    const pageTitle = 'パラちゃんねる';
  document.title = pageTitle;
    const backColor = {
        background: 'gray'
      };

    const prefecturesChange = (event:any) => {
        setprefectures(event.target.value);
    }

    const categorySelectChange = (event:any) => {
        setjob_category_types(event.target.value);
    }

    const employeeSelectChange = (event:any) => {
    setemployment_statuses(event.target.value);
    }

    useEffect(() => {
        if(localStorage.getItem('jobParachannelAuthToken')){
            dispatch(fetchLoginRequest('', '',''));
            if(loginData !== undefined){
              if(loginData.pending){
                setLogin(true);
              }
            }
        }
      }, [dispatch, localStorage.getItem('jobParachannelAuthToken')]);

    useEffect(() => {
        dispatch(fetchRecruitmentsRequest());
        dispatch(fetchRegionsRequest());
    }, [dispatch]);

      useEffect(() => {
        if(prefectureId !== '' || selectJobCategoryTtypes !== '' ||selectEmploymentStatuses !== '' ){
            console.log(prefectureId);
            console.log(selectJobCategoryTtypes);
            console.log(selectEmploymentStatuses);
            setSearchDisabled(false);
        }
      },[prefectureId,selectJobCategoryTtypes,selectEmploymentStatuses])

    let prefectures = [];
    let search_regions = [];
    let employment_statuses = [];
    let job_category_types = [];
    let recruitments1 = [];
    let recruitments2 = [];
    let recruitments3 = [];
    let recruitments4 = [];
    let recruitments5 = [];
    let recruitments6 = [];
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('consideration_conditions', 'true');

    if(regions.prefectures !== undefined){
        prefectures = regions.prefectures;
    }

    if(regions.job_category_types !== undefined){
        job_category_types = regions.job_category_types;
    }

    if(regions.employment_statuses !== undefined){
        employment_statuses = regions.employment_statuses;
    }

    if(regions.search_regions !== undefined){
        search_regions = regions.search_regions;
    }


    if(recruitments.recruitments !== undefined) {
        recruitments6 = recruitments.recruitments[5];
        recruitments1 = recruitments.recruitments[0];
        recruitments2 = recruitments.recruitments[1];
        recruitments3 = recruitments.recruitments[2];
        recruitments4 = recruitments.recruitments[3];
        recruitments5 = recruitments.recruitments[4];
    }




    const jobSearch = () => {
        if (prefectureId || selectJobCategoryTtypes || selectEmploymentStatuses) {
          let query = '';
          const arr = [];
    
          if (prefectureId !== '') {
            arr.push('prefecture_ids[]=' + prefectureId);
          }
          if (selectJobCategoryTtypes !== '') {
            arr.push('job_category_type_ids[]=' + selectJobCategoryTtypes);
          }
          if (selectEmploymentStatuses !== '') {
            arr.push('employment_status_ids[]=' + selectEmploymentStatuses);
          }
          
          query = arr.join('&');
          window.location.href = ('/recruitments/?page=1&' + query);
        }
      };

    const replacedContent = (value: any) => {
        if (value == null) {
            return null
        }
        return value.replaceAll("\n", '<br>');
    }

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };
    
      useEffect(() => {
        // Attach the event listener on component mount
        window.addEventListener('resize', handleResize);
    
        // Clean up the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, [windowSize]);
      

    return (
        <main id="top">
            <section className="mv">
                <div className="list_box">
                    <p>どこで働きたい？</p>
                    <div>
                        <ul>
                            <li><a href="/recruitments/">全国</a></li>
                            {search_regions.map((region: any, index: any) => (
                                <li key={index}><a href={'/recruitments?region_ids[]='+ region.id + '&'+ region.search}>{region.name}</a></li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="cont_box">
                    <div className="wrapper">
                    <form>
                        <h2>お仕事探しをはじめよう</h2>
                        <dl>
                        <dt><img src="assets/img/top/article_icon06.svg" alt=""/>勤務地</dt>
                        <dd>
                            <select name="prefectures" value = {prefectureId} onChange={prefecturesChange}>
                            <option value = ""  disabled>選択してください</option>
                            {prefectures.map((option: any, index: any) => (
                                <option key={index} value={option.id}>
                                {option.name}
                                </option>
                            ))}
                            </select>
                        </dd>
                        <dt><img src="assets/img/top/article_icon07.svg" alt=""/>職種</dt>
                        <dd>
                            <select name="job_category_types"  value = {selectJobCategoryTtypes} onChange={categorySelectChange} >
                                <option value=""  disabled>選択してください</option>
                                {job_category_types.map((option: any, index: any) => (
                                    <option key={index} value={option.id}>
                                    {option.name}
                                    </option>
                                ))}
                            </select>
                        </dd>
                        <dt><img src="assets/img/top/article_icon08.svg" alt=""/>雇用形態</dt>
                        <dd>
                            <select name="employment_status_id"  value = {selectEmploymentStatuses} onChange={employeeSelectChange} >
                                <option value=""  disabled>選択してください</option>
                                {employment_statuses.map((option: any, index: any) => (
                                    <option key={index} value={option.id}>
                                    {option.name}
                                    </option>
                                ))}
                            </select>
                        </dd>
                        </dl>
                        <button type="button"  className={`btn type3 ${searchDisabled ? 'disabled' : ''}`} disabled={searchDisabled}  id="searchButton" onClick={jobSearch}  >絞り込み検索</button>
                    </form>
                    </div>
                </div>
            </section>
            <section className="article wrapper">
                <div>
                    <h2 className="ttl type1">
                        <img src="./assets/img/top/article_icon10.svg" alt=""></img>
                        <span>ピックアップ求人</span>
                    </h2>
                        {recruitments6.length !== 0 ? 
                        <Swiper
                            modules={[Navigation]}
                            spaceBetween={20}
                            navigation
                            slidesPerView={windowSize.width <= 768 ? 2 : 4}
                            keyboard={{
                                enabled: true
                            }}
                            loop
                            >
                                {recruitments6.map((data: any) => {
                                return (
                                <SwiperSlide key={data.id} className ="slider">
                                    <li>
                                        <a href={'/recruitments/'+ data.id }>
                                            <div className ="img_box">
                                                <img src= {data.thumbnail} ></img>
                                            </div>
                                            <h3  className = "ttl">{data.job_title} / {data.company_name}</h3>
                                            <p>{data.salary_form} {data.lower_monthly_basic_salary.toLocaleString()} 〜{data.upper_monthly_basic_salary.toLocaleString()} 円 </p>
                                            <p className = "category">{data.employment_status}</p>
                                            <p className = "new_graduate">{data.new_graduate}</p>
                                            <p>{replacedContent(data.work_location) || ''}</p>
                                            {data.already_applied ? <p className="done" >エントリー済み</p>: <></>}
                                        </a>
                                    </li>
                                </SwiperSlide>
                                );
                            })}
                        </Swiper>: 
                        <div className="load-spinner">
                        <span className="spinner"></span>
                      </div>
                        }
                    <a href="/recruitments/?page=1&employment_status_ids[]=1&employment_status_ids[]=2">すべて見る</a>
                </div>
                <div>
                    <h2 className ="ttl type1">
                        <img src="/assets/img/top/article_icon01.svg" alt=""></img>
                        <span>身体的</span>なことへの配慮がある仕事
                    </h2>
                    {recruitments1.length !== 0 ? 
                        <Swiper
                            modules={[Navigation]}
                            spaceBetween={20}
                            navigation
                            slidesPerView={windowSize.width <= 768 ? 2 : 4}
                            loop
                            >
                                {recruitments1.map((data: any) => {
                                return (
                                <SwiperSlide key={data.id} className ="slider">
                                    <li>
                                        <a href={'/recruitments/'+ data.id}>
                                            <div className ="img_box">
                                                <img src= {data.thumbnail} ></img>
                                            </div>
                                            <h3  className = "ttl">{data.job_title} / {data.company_name}</h3>
                                            <p>{data.salary_form} {data.lower_monthly_basic_salary.toLocaleString()} 〜{data.upper_monthly_basic_salary.toLocaleString()} 円 </p>
                                            <p className = "category">{data.employment_status}</p>
                                            <p className = "new_graduate">{data.new_graduate}</p>
                                            <p>{replacedContent(data.work_location) || ''}</p>
                                            {data.already_applied ? <p className="done" >エントリー済み</p>: <></>}
                                        </a>
                                    </li>
                                </SwiperSlide>
                                );
                            })}
                        </Swiper>: 
                        <div className="load-spinner">
                          <span className="spinner"></span>
                        </div>
                        }
                    <a href="/recruitments/?page=1&handicap_type_ids[]=1">すべて見る</a>
                </div>
                <div>
                    <h2 className="ttl type1">
                        <img src="/assets/img/top/article_icon03.svg" alt=""></img>
                        <span>精神的</span>なことへの配慮がある仕事
                    </h2>
                    {recruitments2.length !== 0 ? 
                        <Swiper
                            modules={[Navigation]}
                            spaceBetween={20}
                            navigation
                            slidesPerView={windowSize.width <= 768 ? 2 : 4}
                            loop
                            >
                                {recruitments2.map((data: any) => {
                                return (
                                <SwiperSlide key={data.id} className ="slider">
                                    <li>
                                        <a href={'/recruitments/'+ data.id}>
                                            <div className ="img_box">
                                                <img src= {data.thumbnail} ></img>
                                            </div>
                                            <h3  className = "ttl">{data.job_title} / {data.company_name}</h3>
                                            <p>{data.salary_form} {data.lower_monthly_basic_salary.toLocaleString()} 〜{data.upper_monthly_basic_salary.toLocaleString()} 円 </p>
                                            <p className = "category">{data.employment_status}</p>
                                            <p className = "new_graduate">{data.new_graduate}</p>
                                            <p>{replacedContent(data.work_location) || ''}</p>
                                            {data.already_applied ? <p className="done" >エントリー済み</p>: <></>}
                                        </a>
                                    </li>
                                </SwiperSlide>
                                );
                            })}
                        </Swiper>: 
                        <div className="load-spinner">
                          <span className="spinner"></span>
                        </div>
                        }
                    <a href="/recruitments/?page=1&handicap_type_ids[]=2">すべて見る</a>
                </div>
                <div>
                    <h2 className="ttl type1">
                        <img src="/assets/img/top/article_icon04.svg" alt=""></img>
                        <span>発達特性</span>への配慮がある仕事
                    </h2>
                    {recruitments3.length !== 0 ? 
                        <Swiper
                            modules={[Navigation]}
                            spaceBetween={20}
                            navigation
                            slidesPerView={windowSize.width <= 768 ? 2 : 4}
                            loop
                            >
                                {recruitments3.map((data: any) => {
                                return (
                                <SwiperSlide key={data.id} className ="slider">
                                    <li>
                                        <a href={'/recruitments/'+ data.id}>
                                            <div className ="img_box">
                                                <img src= {data.thumbnail} ></img>
                                            </div>
                                            <h3  className = "ttl">{data.job_title} / {data.company_name}</h3>
                                            <p>{data.salary_form} {data.lower_monthly_basic_salary.toLocaleString()} 〜{data.upper_monthly_basic_salary.toLocaleString()} 円 </p>
                                            <p className = "category">{data.employment_status}</p>
                                            <p className = "new_graduate">{data.new_graduate}</p>
                                            <p>{replacedContent(data.work_location) || ''}</p>
                                            {data.already_applied ? <p className="done" >エントリー済み</p>: <></>}
                                        </a>
                                    </li>
                                </SwiperSlide>
                                );
                            })}
                        </Swiper>: 
                        <div className="load-spinner">
                          <span className="spinner"></span>
                        </div>
                        }
                    <a href="/recruitments/?page=1&handicap_type_ids[]=3">すべて見る</a>
                </div>
                <div>
                    <h2 className="ttl type1">
                        <img src="/assets/img/top/article_icon09.svg" alt=""></img>
                        <span>知的障がい</span>への配慮がある仕事
                    </h2>
                    {recruitments4.length !== 0 ? 
                        <Swiper
                            modules={[Navigation]}
                            spaceBetween={20}
                            navigation
                            slidesPerView={windowSize.width <= 768 ? 2 : 4}
                            loop
                            >
                                {recruitments4.map((data: any) => {
                                return (
                                <SwiperSlide key={data.id} className ="slider">
                                    <li>
                                        <a href={'/recruitments/'+ data.id}>
                                            <div className ="img_box">
                                                <img src= {data.thumbnail} ></img>
                                            </div>
                                            <h3  className = "ttl">{data.job_title} / {data.company_name}</h3>
                                            <p>{data.salary_form} {data.lower_monthly_basic_salary.toLocaleString()} 〜{data.upper_monthly_basic_salary.toLocaleString()} 円 </p>
                                            <p className = "category">{data.employment_status}</p>
                                            <p className = "new_graduate">{data.new_graduate}</p>
                                            <p>{replacedContent(data.work_location) || ''}</p>
                                            {data.already_applied ? <p className="done" >エントリー済み</p>: <></>}
                                        </a>
                                    </li>
                                </SwiperSlide>
                                );
                            })}
                        </Swiper>: 
                        <div className="load-spinner">
                          <span className="spinner"></span>
                        </div>
                        }
                    <a href="/recruitments/?page=1&handicap_type_ids[]=4">すべて見る</a>
                </div>
                <div>
                    <h2 className="ttl type1">
                        <img src="/assets/img/top/article_icon06.svg" alt=""></img>
                        <span>難病・その他特性</span>への配慮がある仕事
                    </h2>
                    {recruitments5.length !== 0 ? 
                        <Swiper
                            modules={[Navigation]}
                            spaceBetween={20}
                            navigation
                            slidesPerView={windowSize.width <= 768 ? 2 : 4}
                            loop
                            >
                                {recruitments5.map((data: any) => {
                                return (
                                <SwiperSlide key={data.id} className ="slider">
                                    <li>
                                        <a href={'/recruitments/'+ data.id }>
                                            <div className ="img_box">
                                                <img src= {data.thumbnail} ></img>
                                            </div>
                                            <h3  className = "ttl">{data.job_title} / {data.company_name}</h3>
                                            <p>{data.salary_form} {data.lower_monthly_basic_salary.toLocaleString()} 〜{data.upper_monthly_basic_salary.toLocaleString()} 円 </p>
                                            <p className = "category">{data.employment_status}</p>
                                            <p className = "new_graduate">{data.new_graduate}</p>
                                            <p>{replacedContent(data.work_location) || ''}</p>
                                            {data.already_applied ? <p className="done" >エントリー済み</p>: <></>}
                                        </a>
                                    </li>
                                </SwiperSlide>
                                );
                            })}
                        </Swiper>: 
                        <div className="load-spinner">
                          <span className="spinner"></span>
                        </div>
                        }
                    <a href="/recruitments/?page=1&handicap_type_ids[]=5">すべて見る</a>
                </div>
            </section>
            {!isLogin ? (
                <div className="btn_box">
                    <p>登録は<span>1分</span>で完了</p>
                    <a href="/login/"><img src="/assets/img/header_icon03.svg" alt=""/>ログイン／登録</a>
                    <a href="/recruiter_lp/">採用担当者様はこちら</a>
                </div>
            ): null}
            <Column />
            {isLogin ? (
                <section className="resume wrapper">
                    <div className="ttl type2">
                        <p><img src="/assets/img/top/ttl_number03.svg" alt="For You 03"/></p>
                        <h2>あなたの履歴書<span>登録後、マイページから自分の情報を入力すると自動で履歴書が作られます！</span></h2>
                    </div>
                    <a  href="/mypage/profile/"><img src="/assets/img/top/resume_btn.svg" alt="履歴書(PDF)をダウンロード"/></a>
                </section>
            ) : null}
        </main>
    );
  }
  
  export default Indexs;
