import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../redax/reducers/rootReducer";

import { fetchLoginRequest } from "../../redax/actions/loginActions/loginActions";
import { fetchColumnsRequest } from "../../redax/actions/columnsActions/columnsActions";
import ColumnsList from "../../components/ColumnsList/ColumnsList";
import RecruitmentCardList from "../../components/RecruitmentCardList/RecruitmentCardList";
import { fetchRecruitmentsPublicListRequest } from "../../redax/actions/recruitmentsPublicListActions/recruitmentsPublicListActions";
import { columnFetchItemRequest } from "../../redax/actions/columnFetchItemActions/columnFetchItemActions";


export default function Columns() {
  const [company, setCompany] = useState<any[]>([]);
  const [logo, setLogo] = useState('');
  const [name, setName] = useState('');
  const [companyUrl, setCompanyUrl] = useState('');
  const [companyPostCode, setCompanyPostCode] = useState('');
  const [companyPrefecture, setPrefecture] = useState('');
  const [companyCity, setCity] = useState('');
  const [companyAddress, setAddress] = useState('');
  const [companyIndustry, setCompanyIndustry] = useState('');
  const [companyBusinessContent, setBusinessContent] = useState('');
  const [companyFoundedyear, setCompanyFoundedyear] = useState('');
  const [companyFoundedmonth, setFoundedmonth] = useState('');
  const [companyNumber_of_employees, setNumber_of_employees] = useState('');
  const [companyCapital, setCompanyCapital] = useState('');
  const [companySales, setcompanySales] = useState('');
  const [companyStock_listing, setcompanyStock_listing] = useState('');
  const dispatch = useDispatch();
  const loginData = useSelector((state: RootState) => state.login);
  const columns = useSelector((state: RootState) => state.columns);
  const [load, setLoad] = useState<any>(true);
  const navigate = useNavigate();
  const { id } = useParams();
  const columnItem = useSelector((state: RootState) => state.columnItem);

  let article: any = [];
  let recruitment = [];

  
  // useEffect(() => {
  //   if (!localStorage.getItem('jobParachannelAuthToken')) {
  //     navigate('/');
  //   } else {
  //     dispatch(fetchLoginRequest('', '', ''));
  //   }
  // }, [dispatch]);

  // useEffect(() => {
  //   if (loginData.pending && !load) {
  //     dispatch(fetchColumnsRequest(loginData.login.company.id));
  //   }
  // }, [dispatch, loginData, load]);


  useEffect(() => {
      dispatch(columnFetchItemRequest(id));
  }, [dispatch]);

  if(columnItem !== undefined && columnItem.pending){
    article=columnItem;
    // console.log(columnItem);
  }

  useEffect(() => {
    if(columnItem !== undefined){
      article=columnItem;
    }
    if(columnItem !== undefined && columnItem.pending && load) {
      // console.log(article.columnItem.company_id);
      dispatch(fetchColumnsRequest(article.columnItem.company_id));
      dispatch(fetchRecruitmentsPublicListRequest(article.columnItem.company_id));
      setLoad(false);
    }
  }, [dispatch, columnItem]);

  useEffect(() => {
  if(columns !== undefined && columns.pending){
    setCompany(columns.columns.company);

    // console.log(columns.columns.company);
    setLogo(columns.columns.company.logo);
    setName(columns.columns.company.name);
    setCompanyUrl(columns.columns.company.url);
    setCompanyPostCode(columns.columns.company.postcode);
    setPrefecture(columns.columns.company.prefecture.name);
    setCity(columns.columns.company.city.name);
    setAddress(columns.columns.company.address);
    setCompanyIndustry(columns.columns.company.industry.name);
    setBusinessContent(columns.columns.company.business_content);
    setNumber_of_employees(columns.columns.company.number_of_employees);
    setCompanyCapital(columns.columns.company.capital);
    setcompanySales(columns.columns.company.sales);
    setcompanyStock_listing(columns.columns.company.stock_listing.name);
    setCompanyFoundedyear(columns.columns.company.founded.year);
    setFoundedmonth(columns.columns.company.founded.month);

  }
  }, [columns, columns.pending, dispatch]);

  const { recruitments_public_list } = useSelector(
    (state: RootState) => state.recruitmentsPublicList
  );
  if(recruitments_public_list !== undefined) {
    recruitment = recruitments_public_list
  }
  


  return (
    <main id="jobs" className="column_preview">
    <section className="detail wrapper type2">

      <div className='cont_box'>
        <div>
          <article className="column_article">
        <ul className="sns">
          <li>
            <a href="'https://twitter.com/share?url=' + currentUrlEncode"
              target="_blank"
              ><img src="/assets/img/column/col_tw.svg" alt=""
            /></a>
          </li>
          <li>
            <a href="'http://www.facebook.com/share.php?u=' + currentUrl"
              target="_blank"
              ><img src="/assets/img/column/col_fb.svg" alt=""
            /></a>
          </li>
        </ul>            
        <h2>{article.columnItem !== undefined ? article.columnItem.title : '' }</h2>
        <div className="info">
          <div className="view">
            <img src="/assets/img/column/view.svg" alt="" /><span>{article.columnItem !== undefined ? article.columnItem.view : '' }</span>view
          </div>
        </div>
        <div className="article" contentEditable='true' dangerouslySetInnerHTML={{ __html:article.columnItem !== undefined ? article.columnItem.content : '' }}></div>
      </article> 
        </div>
          <ColumnsList company={company} showMoreBtn={true} ></ColumnsList>
          <RecruitmentCardList recruitment={recruitment.recruitments} />
        </div>
        
      <div className="sidebar">
      {Object.keys(company.length > 0) ?
        <div className="tab_box">
          <div className="logo_box">
            <img src={logo} alt="{name}"/>
            <h2>{name}</h2>
          </div>
          <div className="follow_box">
            <p>FOLLOW 1</p>
          </div>
          <dl>
            {companyUrl ?
            <>
              <dt>URL</dt>
              <dd>
                <a href="{companyUrl}" target="_blank">{companyUrl}<span className="material-icons">launch</span>
                </a>
              </dd>
            </>
            : <></>}
            {companyPostCode ?
            <>
              <dt>住所<br/>（本社）</dt>
              <dd>〒 {companyPostCode}<br/>{companyPrefecture}{companyCity}{companyAddress}</dd>
            </>
            : <></>}
              <dt>業種分類</dt>
              <dd>{companyIndustry}</dd>

            {companyBusinessContent ?
            <>
              <dt>事業内容</dt>
              <dd>
              <span className="white_space">{companyBusinessContent}</span>
            </dd>
            </>
            : <></>}
            {companyFoundedyear ?
            <>
              <dt> 設立年月 </dt>
              <dd>{companyFoundedyear}年{companyFoundedmonth}月</dd>
            </>
            : <></>}
            {companyNumber_of_employees ?
            <>
              <dt>従業員数</dt>
              <dd>{companyNumber_of_employees}人 </dd>
            </>
            : <></>}
            {companyCapital ?
            <>
              <dt>資本金</dt>
              <dd>{companyCapital}</dd>
            </>
            : <></>}
            {companySales ?
            <>
              <dt>売上高</dt>
              <dd>{companySales}</dd>
            </>
            : <></>}
            {companyStock_listing ?
            <>
              <dt>株式</dt>
              <dd>{companyStock_listing}</dd>
            </>
            : <></>}
            <dt>障がい者<br/>雇用実績</dt>
            <dd>無</dd>
          </dl>
        </div>
        : <></>}
      </div>
      </section>
    </main>
  );
}

