import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { topRecruiterTypes } from "../../ActionTypes/topRecruiterTypes";
import { fetchTopRecruiterFailure, fetchTopRecruiterSuccess } from "../../actions/topRecruiterActions/topRecruiterActions";


const getTopRecruiter = (id: any, selected_id: any) =>
  axios.get(API_BASE_ADDRESS + '/top/recruiter/' + id + '?range=' + selected_id,  {
    headers: { Accept: 'application/json' }
  });

function* fetchTopRecruiterSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getTopRecruiter, action.id, action.selected_id);
    yield put(
      fetchTopRecruiterSuccess({
        recruiter: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchTopRecruiterFailure({
        error: e.message
      })
    );
  }
}

function* TopRecruiterSaga() {
  yield takeLatest(topRecruiterTypes.FETCH_TOP_RECRUITER_REQUEST, fetchTopRecruiterSaga);
}
export default TopRecruiterSaga;