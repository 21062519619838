import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { fetchLoginRequest } from '../../redax/actions/loginActions/loginActions';
import { RootState } from "../../redax/reducers/rootReducer";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import './MypageSideBar.scss';

function MypageSideBar(props: { resource: any , familyName: any, currentList: any}) {

    let resource: any;
    // let name ;
    let getTitleString = '';
    let getMypageURL = '';
    let getGuidePageURL = '';
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [has_unread_message, setHasUnreadMessage] = useState(false);
    const [has_unread_scout, setHasUnreadScout] = useState(false);
    const [has_unread_application, setHasUnreadApplication] = useState(false);
    let isShow = true;
    const [rate, setRate] = useState(0);
    const [name, setName] = useState('');
    const [searchParams,setSearchParams] = useState("")
    let routePath = window.location.pathname;
    let disabled = false;
    let search_params = '';
    let currentList = ''
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loginData = useSelector((state: RootState) => state.login);
    if(routePath.includes('init')) {
      isShow = false;
      disabled = true;
    }
    useEffect(() => {
      // dispatch(fetchLoginRequest('', '',''));
    }, [dispatch]);  

    if(props) {
        resource  = props.resource;
        // name = props.familyName;
        currentList = props.currentList;
    }
    if(currentList == 'ApplicantList'){
      getTitleString = 'マイページ';
      getMypageURL = '/mypage/';
      getGuidePageURL = '/guide/';
    } else {
      getTitleString = '企業様マイページ';
      getMypageURL = '/recruiter/';
      getGuidePageURL = '/guide/?tab=3';

    }

    useEffect(() => {
      if(isMobile) {
        document.getElementById('nav-menu')!.style.height = '0'
        document.getElementById('nav-menu')!.style.padding = '0 30px'
        document.getElementById('nav-menu')!.style.transition = 'all 0.3s';
      }
      if (loginData.pending) {
        setHasUnreadMessage(loginData.login.has_unread_message);
        setHasUnreadScout(loginData.login.unread_scout);
        setHasUnreadApplication(loginData.login.has_unread_application);
        setName(loginData.login.resource.family_name);
        // setSearchParams(loginData.login.search_params);
        if(loginData.login.authenticate.resource_type !== 'Companies::Recruiter') {
          if(window.location.pathname.includes('recruiter')) {
            navigate('/mypage/');
            return;
          }
        } else {
          if(window.location.pathname.includes('mypage')) {
            navigate('/recruiter/');
            return;
          }
        }
        if(currentList == 'ApplicantList'){
          setRate(loginData.login.completeness.rate);
        }
      }      
    }, [loginData.pending]);

    const handleClick = () => {      
      if (isMenuOpen) {
        document.body.className = ''
        document.getElementById('nav-menu')!.style.height = '0'
        document.getElementById('nav-menu')!.style.padding = '0 30px'
      } else {
        document.body.className = 'overflow'
        document.getElementById('nav-menu')!.style.height = window.innerHeight + 'px'
        document.getElementById('nav-menu')!.style.padding = ''
      }
      document.getElementById('nav-menu')!.style.transition = 'all 0.3s';
      setIsMenuOpen(!isMenuOpen);
    };


    const isInclude = (value: any) => {
      return routePath.match(new RegExp(value));
    };

    const isMatch = (value: any) => {
      if(routePath === value) {
        return true
      } else {
        return false

      }
    };
    const isMatchDynamic = (value: any) => {
      const reg = new RegExp(value + '[0-9]')
      return routePath.match(reg)
    }

    return (
        <section id="sidebar">
        <div>
          <h1>
            <a href="/"><img src="/assets/img/logo.svg" alt="パラちゃんねる" /></a>
          </h1>
          <div className="page_ttl_area">
            {isShow ?
            <><p className="page_ttl" >
              <a href={getMypageURL}>{ getTitleString }</a>
            </p>
            <div className="name_box">
              <h2>ようこそ <span v-show="name"> { name }</span> さん</h2>
            </div></>
            : <></>}
          </div>
          {/* <transition
            name="toggle-slide"
            @before-enter="beforeEnter"
            @enter="enter"
            @before-leave="beforeLeave"
            @leave="leave"
          > */}
            <nav id="nav-menu">
              {/* <component :is="currentList" :disabled="disabled"/> */}
              {currentList === 'ApplicantList' ?
                <ul className={disabled ? 'disabled' : ''}>
                <li className={isMatch('/mypage/') ? 'active' : ''}>
                  <Link onClick={disabled ? (e) => {e.preventDefault()} : () => {navigate('/mypage/');window.location.reload();}} to="#">
                    <img src="/assets/img/sidebar_icon01.svg" alt="" />
                    <span className="icon_box">マイページTOP</span>
                  </Link>
                </li>
                <li>
                  <Link onClick={disabled ? (e) => {e.preventDefault()} : () => {navigate(`/recruitments/?${searchParams}`);window.location.reload();}} to="#">
                    <img src="/assets/img/sidebar_icon10.svg" alt="" />
                    <span className="icon_box">お仕事を探す</span>
                  </Link>
                </li>
                <li className={` ${isMatch('/mypage/scouts/') ? 'active' : ''} ${has_unread_scout ? 'new_icon' : ''}`}>
                  <Link onClick={disabled ? (e) => {e.preventDefault()} : () => {navigate('/mypage/scouts/');window.location.reload();}} to="#">
                    <img src="/assets/img/sidebar_icon07.svg" alt="" />
                    <span className="icon_box">あなたに興味がある企業</span>
                  </Link>
                </li>
                <li className={isInclude('/mypage/profile/') ? 'active' : ''}>
                  <Link onClick={disabled ? (e) => {e.preventDefault()} : () => {navigate('/mypage/profile/');window.location.reload();}} to="#">
                    <img src="/assets/img/sidebar_icon06.svg" alt="" />
                    <span className="icon_box">web履歴書</span>
                    <span>{rate}％</span>
                  </Link>
                  <ul style={{ display: isInclude('/mypage/profile') ? 'block' : 'none' }}>
                    <li className={isMatch('/mypage/profile/') ? 'active' : ''}>
                      <Link onClick={disabled ? (e) => {e.preventDefault()} : () => {navigate('/mypage/profile/');window.location.reload();}} to="#">プロフィール</Link>
                    </li>
                    <li className={isMatch('/mypage/profile2/') ? 'active' : ''}>
                      <Link onClick={disabled ? (e) => {e.preventDefault()} : () => {navigate('/mypage/profile2/');window.location.reload();}} to="#">障がいについて</Link>
                    </li>
                    <li className={isMatch('/mypage/profile3/') ? 'active' : ''}>
                      <Link onClick={disabled ? (e) => {e.preventDefault()} : () => {navigate('/mypage/profile3/');window.location.reload();}} to="#">職務経歴</Link>
                    </li>
                    <li className={isMatch('/mypage/profile4/') ? 'active' : ''}>
                      <Link onClick={disabled ? (e) => {e.preventDefault()} : () => {navigate('/mypage/profile4/');window.location.reload();}} to="#">スキル</Link>
                    </li>
                    <li className={isMatch('/mypage/profile5/') ? 'active' : ''}>
                      <Link onClick={disabled ? (e) => {e.preventDefault()} : () => {navigate('/mypage/profile5/');window.location.reload();}} to="#">自己PR</Link>
                    </li>
                    <li className={isMatch('/mypage/profile6/') ? 'active' : ''}>
                      <Link onClick={disabled ? (e) => {e.preventDefault()} : () => {navigate('/mypage/profile6/');window.location.reload();}} to="#">希望条件</Link>
                    </li>
                    <li className={isMatch('/mypage/profile7/') ? 'active' : ''}>
                      <Link onClick={disabled ? (e) => {e.preventDefault()} : () => {navigate('/mypage/profile7/');window.location.reload();}} to="#">企業に伝えたい項目</Link>
                    </li>
                  </ul>
                </li>
                <li className={`${isInclude('/mypage/applications/') ? 'active' : ''} ${has_unread_message ? 'new_icon' : ''}`}>
                  <Link onClick={disabled ? (e) => {e.preventDefault()} : () => {navigate('/mypage/applications/');window.location.reload();}} to="#">
                    <img src="/assets/img/sidebar_icon09.svg" alt="" />
                    <span className="icon_box">応募管理</span>
                  </Link>
                </li>
                <li className={isMatch('/mypage/schedules/') ? 'active' : ''}>
                  <Link onClick={disabled ? (e) => {e.preventDefault()} : () => {navigate('/mypage/schedules/');window.location.reload();}} to="#">
                    <img src="/assets/img/sidebar_icon03.svg" alt="" />
                    <span className="icon_box">スケジュール</span>
                  </Link>
                </li>
                <li className={isMatch('/mypage/settings/') ? 'active' : ''}>
                  <Link onClick={disabled ? (e) => {e.preventDefault()} : () => {navigate('/mypage/settings/');window.location.reload();}} to="#">
                    <img src="/assets/img/sidebar_icon04.svg" alt="" />
                    <span className="icon_box">設定</span>
                  </Link>
                </li>
              </ul>
              :
              <ul className={disabled ? 'disabled' : ''}>
                <li className={isMatch('/recruiter/') ? 'active' : ''}>
                  <Link onClick={disabled ? (e) => {e.preventDefault()} : () => {navigate('/recruiter/');window.location.reload();}} to="#">
                    <img src="/assets/img/sidebar_icon01.svg" alt=""/><span className="icon_box">マイページTOP</span>
                  </Link>
                </li>
                <li className={` ${isMatch('/recruiter/company/') ? 'active' : ''} }`}>
                  <Link onClick={disabled ? (e) => {e.preventDefault()} : () => {navigate('/recruiter/company/');window.location.reload();}} to="#">
                    <img src="/assets/img/sidebar_icon05.svg" alt="" /><span className="icon_box">会社概要</span>
                  </Link>
                </li>
                <li className={isInclude('/recruiter/recruitments/') ? 'active' : ''}>
                  <Link onClick={disabled ? (e) => {e.preventDefault()} : () => {navigate('/recruiter/recruitments/');window.location.reload();}} to="#">
                    <img src="/assets/img/sidebar_icon06.svg" alt="" /><span className="icon_box">求人投稿・管理</span>
                  </Link>
                  <ul style={{ display: isInclude('/recruiter/recruitments/') ? 'block' : 'none' }}>
                    <li className={isMatch('/recruiter/recruitments/') ? 'active' : ''}>
                      <Link onClick={disabled ? (e) => {e.preventDefault()} : () => {navigate('/recruiter/recruitments/');window.location.reload();}} to="#">一覧</Link>
                    </li>
                    <li className={isMatch('/recruiter/recruitments/create/') ? 'active' : ''}>
                      <Link onClick={disabled ? (e) => {e.preventDefault()} : () => {navigate('/recruiter/recruitments/create/');window.location.reload();}} to="#">新規</Link>
                    </li>
                  </ul>
                </li>
                <li className={isInclude('/recruiter/scouts/') ? 'active' : ''}>
                  <Link onClick={disabled ? (e) => {e.preventDefault()} : () => {navigate('/recruiter/scouts/');window.location.reload();}} to="#">
                    <img src="/assets/img/sidebar_icon07.svg" alt="" /><span className="icon_box">スカウト</span>
                  </Link>
                  <ul style={{ display: isInclude('/recruiter/scouts/') ? 'block' : 'none' }}>
                    <li className={isMatch('/recruiter/scouts/') ? 'active' : ''}>
                      <Link onClick={disabled ? (e) => {e.preventDefault()} : () => {navigate('/recruiter/scouts/');window.location.reload();}} to="#">人材検索</Link>
                    </li>
                    <li className={isInclude('/recruiter/scouts/history/') ? 'active' : ''}>
                      <Link onClick={disabled ? (e) => {e.preventDefault()} : () => {navigate('/recruiter/scouts/history/');window.location.reload();}} to="#">メッセージ履歴</Link>
                    </li>
                  </ul>
                </li>
                <li className={`${isInclude('/recruiter/applications/') ? 'active' : ''} ${has_unread_message || has_unread_application ? 'new_icon' : ''}`}>
                  <Link onClick={disabled ? (e) => {e.preventDefault()} : () => {navigate('/recruiter/applications/');window.location.reload();}} to="#">
                    <img src="/assets/img/sidebar_icon09.svg" alt="" /><span className="icon_box">応募管理</span>
                  </Link>
                  <ul style={{ display: isInclude('/recruiter/applications/') ? 'block' : 'none' }}>
                    <li className={isMatch('/recruiter/applications/') ? 'active' : ''}>
                      <Link onClick={disabled ? (e) => {e.preventDefault()} : () => {navigate('/recruiter/applications/');window.location.reload();}} to="#">一覧</Link>
                    </li>
                    <li className= {isMatchDynamic('/recruiter/applications/') ? 'active' : 'passive'}>
                      <Link to=''>詳細</Link>
                    </li>
                  </ul>
                </li>
                <li className={isInclude('/recruiter/schedules/') ? 'active' : ''}>
                  <Link onClick={disabled ? (e) => {e.preventDefault()} : () => {navigate('/recruiter/schedules/');window.location.reload();}} to="#">
                    <img src="/assets/img/sidebar_icon03.svg" alt="" />
                    <span className="icon_box">スケジュール</span>
                  </Link>
                </li>
                <li className={isInclude('/recruiter/columns/') ? 'active' : ''}>
                  <Link onClick={disabled ? (e) => {e.preventDefault()} : () => {navigate('/recruiter/columns/');window.location.reload();}} to="#">
                    <img src="/assets/img/sidebar_icon08.svg" alt="" /><span className="icon_box">コラム</span>
                  </Link>
                  <ul style={{ display: isInclude('/recruiter/columns/') ? 'block' : 'none' }}>
                    <li className={isMatch('/recruiter/columns/') ? 'active' : ''}>
                      <Link onClick={disabled ? (e) => {e.preventDefault()} : () => {navigate('/recruiter/columns/');window.location.reload();}} to="#">一覧</Link>
                    </li>
                    <li className={isMatch('/recruiter/columns/create/') ? 'active' : ''}>
                      <Link onClick={disabled ? (e) => {e.preventDefault()} : () => {navigate('/recruiter/columns/create/');window.location.reload();}} to="#">新規</Link>
                    </li>
                    <li className= {isMatchDynamic('/recruiter/media/') ? 'active' : 'passive'}>
                      <Link to='/recruiter/media/'>画像</Link>
                    </li>
                  </ul>
                </li>
                <li className={isMatch('/recruiter/plan/') ? 'active' : ''}>
                  <Link onClick={disabled ? (e) => {e.preventDefault()} : () => {navigate('/recruiter/plan/');window.location.reload();}} to="#">
                    <img src="/assets/img/sidebar_icon11.svg" alt=""/><span className="icon_box">プラン/お支払い</span>
                  </Link>
                </li>
                <li className={isMatch('/recruiter/settings/') ? 'active' : ''}>
                  <Link onClick={disabled ? (e) => {e.preventDefault()} : () => {navigate('/recruiter/settings/');window.location.reload();}} to="#">
                    <img src="/assets/img/sidebar_icon04.svg" alt="" />
                    <span className="icon_box">設定</span>
                  </Link>
                </li>                
              </ul>
              }
              
              <a href={getGuidePageURL} className="banner" target="_blank">
                <img src="/assets/img/company/banner.png" alt="簡単！使い方ガイドはこちら" />
              </a>
            </nav>
            <div className={`menu_btn ${isMenuOpen ? 'active' : ''}`} onClick={handleClick}>
            <span></span>
            <span></span>
            <span></span>
            </div>
        </div>
      </section>
    );
  }
  
  export default MypageSideBar;