import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redax/reducers/rootReducer";
import { useEffect } from "react";
import { fetchArticlesRequest } from "../../redax/actions/articlesActions/articlesActions";

function Column() {
  const dispatch = useDispatch();
  const { articles } = useSelector(
    (state: RootState) => state.articles
  );

  let nonParties = [];
  let parties = [];



  if(articles !== undefined){
    for(let i = 0; i < articles.length; i++){
      if(articles[i].category === "parties"){
        parties.push(articles[i]);
        parties.slice(0, 4);
      } else {
        nonParties.push(articles[i]);
        nonParties.slice(0, 4);
      }
    }
  }
  useEffect(() => {
    dispatch(fetchArticlesRequest());
  }, [dispatch]);

    
    return (
        <section className="column">
        <div className="wrapper">
          <div className="ttl type2">
            <p><img src="assets/img/top/ttl_number02.svg" alt="For You 02"/></p>
            <h2>お仕事で役立つコラム<span>働く側、雇用する側、第三者、様々な視点でつづるコラムリレー</span></h2>
          </div>
          <h3 className="ttl">● 働く側</h3>
          <ul id="parties">
          {parties.map((data: any,) => (
               <li key={data.id}>
                  <a target="_blank" rel="noopener noreferrer" href={data.url}>
                    <div className="img_box"><img src={data.thumbnail} alt= {data.title}></img></div>
                    <div className="text_box">
                      <h4>{ data.title }</h4>
                      <p>{ data.date}</p>
                        {data.tags.map((tag: any, index: any) => (
                          <ul key={index}>
                            <li>{tag}</li>
                          </ul>
                        ))}
                    </div>
                  </a>
                </li>
            ))}
          </ul>
          <h3 className="ttl">● 雇用する側</h3>
          <ul id="nonParties">
          {nonParties.map((data: any,) => (
               <li key={data.id}>
                  <a href={data.url}>
                    <div className="img_box"><img src={data.thumbnail} alt= {data.title}></img></div>
                    <div className="text_box">
                      <h4>{ data.title }</h4>
                      <p>{ data.date}</p>
                        {data.tags.map((tag: any, index: any) => (
                          <ul key={index}>
                            <li>{tag}</li>
                          </ul>
                        ))}
                    </div>
                  </a>
                </li>
            ))}
          </ul>
          <a target="_blank" rel="noopener noreferrer" href="https://www.parachannel.jp/" className="btn type2">コラムをみる<img src="assets/img/top/arrow03.svg" alt="ブログをみる"/></a>
        </div>
      </section>
    );
  }
  
  export default Column;